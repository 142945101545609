export interface IAppState {
    sectionIndex: number;
}




const initialState: IAppState = {
    sectionIndex: 0
}

export function rootReducer(state: IAppState = initialState, action): IAppState {
    console.log(action);
    switch (action.type) {

        case 'SET_SECTION_INDEX':
            return { sectionIndex: state.sectionIndex = action.payload }


        default:
            return state;
    }
}