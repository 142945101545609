import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getSelectedQues'
})

export class SelectedQuesTestModule implements PipeTransform {
    transform(value: any, args?: string): string {
        console.log({ value });
        const TYPE = args;
        switch (TYPE) {
            case 'GROUPS': {
                let sections = [];
                if (!(value && value.length > 0)) return null;
                value.forEach((grp, i) => grp.sections.forEach(sec => sections.push(sec)));
                sections = sections && sections.length > 0 ? [...sections] : [];
                let filters = sections.filter(sec => { console.log(sec); return sec.type === 'FILTER' })
                filters = filters && filters.length > 0 ? [...filters] : [];
                let count = 0;
                for (let filter of filters)
                    count += filter.data.num_of_questions ? filter.data.num_of_questions : 0;
                const result = filters.length === 0 ? null : count > 1 ? `${count} Questions` : `${count} Question`;
                return result;
            }
            case 'GROUP': {
                let sections = [];
                if (!value) return null;
                value.sections.forEach(sec => sections.push(sec));
                sections = sections && sections.length > 0 ? [...sections] : [];
                let filters = sections.filter(sec => { console.log(sec); return sec.type === 'FILTER' })
                filters = filters && filters.length > 0 ? [...filters] : [];
                let count = 0;
                for (let filter of filters)
                    count += filter.data.num_of_questions ? filter.data.num_of_questions : 0;
                const result = filters.length === 0 ? null : count > 1 ? `${count} Questions` : `${count} Question`;
                return result;
            }
        }

    }
}