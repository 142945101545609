import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { logicFolderConfigSchema, logicFolderErrorMessages, logicFolderSchema, logicJumpsSchema, logicSectionSchema, logicSetLeftSectionSchema, logicSetSchema, logicSubSectionSchema, variableSchema } from '../../../content/pages/subject/add-topic-subtopic/subtopic/lecture/lecture-content/logical-lesson/logic';
import { DataService } from '../dataService/data-service.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class LogicService {
  BASE_URL = environment.base_url;
  authUser: any;

  currentLogicLessonId: string = '';
  currentLogicFolderId: string = '';
  currentLogicFolderIndex = -1;
  currentLogicSetData: logicSetSchema[] = [];
  perticularLogicSet: logicSetSchema = null;
  currentLogicSetIndex = -1;
  logicFolderConfigInfo: logicFolderConfigSchema = null;
  logicLessonData: {
    id: string,
    lesson_data: logicFolderSchema[]
  };
  // readonly logicLessonData$ = this.logicLessonData.asObservable();
  logicFolderData = new BehaviorSubject<logicFolderSchema>(null);
  readonly logicFolderData$ = this.logicFolderData.asObservable();
  logicSectionData = new BehaviorSubject<logicSectionSchema[]>([]);
  readonly logicSectionData$ = this.logicSectionData.asObservable();
  logicLeftSectionData = new BehaviorSubject<logicSetLeftSectionSchema[]>([]);
  readonly logicLeftSectionData$ = this.logicLeftSectionData.asObservable();
  activeLeftSectionIndex = new BehaviorSubject<number>(0);
  readonly activeLeftSectionIndex$ = this.activeLeftSectionIndex.asObservable();
  activeLogicSetIndex = new BehaviorSubject<number>(0);
  readonly activeLogicSetIndex$ = this.activeLogicSetIndex.asObservable();
  activeLogicSectionIndex = new BehaviorSubject<number>(0);
  readonly activeLogicSectionIndex$ = this.activeLogicSectionIndex.asObservable();
  leftSectionMappingUpdated = new BehaviorSubject<logicSectionSchema[]>([]);
  readonly leftSectionMappingUpdated$ = this.leftSectionMappingUpdated.asObservable();
  sectioncollapse = new BehaviorSubject<boolean>(false);
  readonly sectioncollapse$ = this.sectioncollapse.asObservable();
  variableData = new BehaviorSubject<variableSchema[]>([]);
  readonly variableData$ = this.variableData.asObservable();

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient,
    private dataService: DataService,
    private commonService: CommonService) 
  {
    this.authUser = this.commonService.getAuthUser();
  }

  editLogicalLessonData(id: any, Logical_Lesson_JSON: object) {
    // console.log('editLogicalLessopn data called');
    return this.http.patch(`${this.BASE_URL}/edit/logic/${id}/`, Logical_Lesson_JSON,  { headers: this.headers })
  }

  // updateLogicFolderData(logicLesssonId: number, object: any) {
  //   this.editLogicalLessonData(logicLesssonId, object);
  // }

  /**
   * @returns total no of sections
   */
  getTotalSections() {
    let total = 0;
    for (let l_set of this.currentLogicSetData) {
      total += l_set.sections.length;
    }
    return total;
  }

  /**
   * function for check a value is empty or not
   * @param value to check
   * @returns true/false
   */
  checkEmptyValue(value) {
    if(value === null) {
      return true;
    }
    else if(value === undefined) {
      return true;
    }
    else if(value === '') {
      return true;
    }
    return false;
  }

  /**
   * Update left section mapping with section on insert or delete
   * @param index left section index to check 
   * @param mode INSERT | DELETE
   * @returns active logic set data after map left section
   */
  updateLeftSectionMappingOnAction(index: number, mode: string) {
    if (this.currentLogicSetData.length === 0) return;
    const possibleLeftSectionIndexs = Array.from(this.logicLeftSectionData.value.keys());
    const tempLogicSetData = [...this.currentLogicSetData];
    tempLogicSetData.forEach(eachSet => {
      const sections = eachSet.sections;
      if(sections.length) {
        sections.forEach((section, i) => {
          if (section.allsections.length > 0) {
            const indextobePresent = section.allsections[0].leftSectionIndex.toString();
            if(indextobePresent !== 'all') {
              if(mode === 'INSERT') {
                if(index >= parseInt(indextobePresent)) {
                  section.allsections[0].leftSectionIndex = index + 1;
                }
              }
              else {
                if(index === parseInt(indextobePresent)) {
                  section.allsections[0].leftSectionIndex = 'all';
                }
                if(index > parseInt(indextobePresent)) {
                  section.allsections[0].leftSectionIndex = index - 1;
                }
              }
            }
          }
        });
      }
    });
    console.log('UPDATED LEFT SECTION', tempLogicSetData);
    return [...tempLogicSetData];
  }

  /**
   * check logic folder has any error or not
   * @param leftSectionData left section data for logic folder
   * @param logicSetData all logic set data for logic folder
   * @returns "{ isError: boolean, msg: string[] }"
   */
  checkLogicFolderError(leftSectionData: logicSetLeftSectionSchema[], logicSetData: logicSetSchema[]) {
    let isError = false;
    let errors = [];

    /* check all errors for left-sections */
    for (let l_section of leftSectionData) {
      /* check error from child component */
      if (l_section.error) {
        isError = true;
        const ERROR = logicFolderErrorMessages.leftSectionEmpty;
        let isAlreadyExist = errors.some(err => err.id === ERROR.id);
        if (!isAlreadyExist) errors.push({ id: ERROR.id, msg: ERROR.msg });
      }
    }

    /* check all errors for sections (means right-sections for split screen) */
    for (let logic_set of logicSetData) {
      console.log(logic_set);
      if(logic_set.logicJumps && logic_set.logicJumps.error) {
        isError = true;
        const ERROR = logicFolderErrorMessages.logicSetJumpError;
        let isAlreadyExist = errors.some(err => err.id === ERROR.id);
        if (!isAlreadyExist) errors.push({ id: ERROR.id, msg: ERROR.msg });
      }
      // If no sections added inside a logic set
      if(logic_set.sections.length == 0) {
        isError = true;
        const ERROR = logicFolderErrorMessages.logicSetEmptySection;
        let isAlreadyExist = errors.some(err => err.id === ERROR.id);
        if (!isAlreadyExist) errors.push({ id: ERROR.id, msg: ERROR.msg });
      }
      for (let section of logic_set.sections) {
        /* check error from child component */
        if (section.error) {
          isError = true;
          const ERROR = logicFolderErrorMessages.logicSectionError;
          let isAlreadyExist = errors.some(err => err.id === ERROR.id);
          if (!isAlreadyExist) errors.push({ id: ERROR.id, msg: ERROR.msg });
        }
        /* section exist any component like (text/solution/question etc...) or not */
        if (section.allsections.length <= 0) {
          isError = true;
          const ERROR = logicFolderErrorMessages.logicSectionEmpty;
          let isAlreadyExist = errors.some(err => err.id === ERROR.id);
          if (!isAlreadyExist) errors.push({ id: ERROR.id, msg: ERROR.msg });
        }
      }
    }
    return isError ? { isError: isError, msg: errors } : { isError: false, msg: [] };
  }

  /**
   * check logic set has any error or not
   * @param currentLogicSet perticular logic set data
   * @returns "{ isError: boolean, msg: string[] }"
   */
  checkLogicSetError(currentLogicSet: logicSetSchema) {
    let isError = false;
    let errors = [];

    if(currentLogicSet.logicJumps && currentLogicSet.logicJumps.error) {
      isError = true;
      const ERROR = logicFolderErrorMessages.logicSetJumpError;
      let isAlreadyExist = errors.some(err => err.id === ERROR.id);
      if (!isAlreadyExist) errors.push({ id: ERROR.id, msg: ERROR.msg });
    }
    // If no sections added inside a logic set
    if(currentLogicSet.sections.length == 0) {
      isError = true;
      const ERROR = logicFolderErrorMessages.logicSetEmptySection;
      let isAlreadyExist = errors.some(err => err.id === ERROR.id);
      if (!isAlreadyExist) errors.push({ id: ERROR.id, msg: ERROR.msg });
    }
    for (let section of currentLogicSet.sections) {
      /* check error from child component */
      if (section.error) {
        isError = true;
        const ERROR = logicFolderErrorMessages.logicSectionError;
        let isAlreadyExist = errors.some(err => err.id === ERROR.id);
        if (!isAlreadyExist) errors.push({ id: ERROR.id, msg: ERROR.msg });
      }
      /* section exist any component like (text/solution/question etc...) or not */
      if (section.allsections.length <= 0) {
        isError = true;
        const ERROR = logicFolderErrorMessages.logicSectionEmpty;
        let isAlreadyExist = errors.some(err => err.id === ERROR.id);
        if (!isAlreadyExist) errors.push({ id: ERROR.id, msg: ERROR.msg });
      }
    }
    return isError ? { isError: isError, msg: errors } : { isError: false, msg: [] };
  }

  /**
   * check logic sections has any error or not
   * @param currentLogicSection logic set's logic sections data
   * @returns true/false
   */
  checkLogicSectionError(currentLogicSection: logicSectionSchema) {
    if(currentLogicSection.allsections.length === 0) return true;
    let subSection = currentLogicSection.allsections[0];
    if(subSection.logicJumps.error) return true;
    if(subSection.type === 'SOLUTION' || subSection.type === 'TEXT' || subSection.type === 'VIDEO') {
      if(!subSection.title) return true;
      if(!subSection.description) return true;
      if(subSection.type === 'VIDEO' && !subSection.videoLink) return true;
    }
    else {
      let subSectionOptionLength = subSection ? subSection.options.length : 0;
      /* check if the question contains options or not */
      if (subSectionOptionLength <= 0) return true;
      if (!subSection.question.textValue) return true;
      /* check if the question's answer is defined or not */
      if (subSectionOptionLength > 0 && (subSection.questionType != 'TEQ' && subSection.answers.length === 0)) return true;
    }
    return false;
  }

  /**
   * check a logic jump configuration has any error or not
   * @param tempLogicJumpConfigData logic jump configure data for a set or section
   * @returns "{ isError: boolean, msg: string[] }"
   */
  checkLogicJumpFormError(tempLogicJumpConfigData: logicJumpsSchema) {
    let isErrorFlag = false;
    let errorsList = [];
    const typeOfLogicJump = tempLogicJumpConfigData.type;
    if(
      (typeOfLogicJump === 'SECTION' && this.checkEmptyValue(tempLogicJumpConfigData.alwaysBlock.questionIndex)) ||
      (typeOfLogicJump === 'SET' && this.checkEmptyValue(tempLogicJumpConfigData.alwaysBlock.setIndex))
    ) {
      isErrorFlag = true;
      const ERROR = logicFolderErrorMessages.logicJumpAlwaysBlockError;
      let isAlreadyExist = errorsList.some(err => err.id === ERROR.id);
      if (!isAlreadyExist) errorsList.push({ id: ERROR.id, msg: ERROR.msg });
    }
    let ruleError = false;
    tempLogicJumpConfigData.rules.forEach((eachRule) => {
      let conditionError = false;
      let thenError = false;
      eachRule.conditions.forEach((eachCondition) => {
        if(this.checkEmptyValue(eachCondition.selectedValue)) {
          conditionError = true;
        }
        if(this.checkEmptyValue(eachCondition.selectedOptionValue)) {
          conditionError = true;
        }
      });

      if(
        (typeOfLogicJump === 'SECTION' && this.checkEmptyValue(eachRule.thenBlock.jumpCondition.questionIndex)) ||
        (typeOfLogicJump === 'SET' && this.checkEmptyValue(eachRule.thenBlock.jumpCondition.setIndex))
      ) {
        thenError = true;
      }

      eachRule.thenBlock.arithmaticConditions.forEach((eachArithmatic) => {
        if(this.checkEmptyValue(eachArithmatic.sourceVariable) || this.checkEmptyValue(eachArithmatic.destinationVariable)) {
          thenError = true;
        }
        else {
          const sourceVariableMatch = this.variableData.value.find((eachVariable) => (eachVariable.id === eachArithmatic.sourceVariable));
          const destinationVariableMatch = this.variableData.value.find((eachVariable) => (eachVariable.id === eachArithmatic.destinationVariable));
          if((eachArithmatic.sourceVariable !== 'ENTER_NUMBER' && !sourceVariableMatch) || !destinationVariableMatch) {
            thenError = true;
          }
        }
        if(eachArithmatic.sourceVariable === 'ENTER_NUMBER' && this.checkEmptyValue(eachArithmatic.numericValue)) {
          thenError = true;
        }
      })

      if(conditionError) {
        ruleError = true;
        isErrorFlag = true;
        const ERROR = logicFolderErrorMessages.logicJumpConditionError;
        let isAlreadyExist = errorsList.some(err => err.id === ERROR.id);
        if (!isAlreadyExist) errorsList.push({ id: ERROR.id, msg: ERROR.msg });
      }
      if(thenError) {
        ruleError = true;
        isErrorFlag = true;
        const ERROR = logicFolderErrorMessages.logicJumpThenError;
        let isAlreadyExist = errorsList.some(err => err.id === ERROR.id);
        if (!isAlreadyExist) errorsList.push({ id: ERROR.id, msg: ERROR.msg });
      }
    })
    if(ruleError) {
      isErrorFlag = true;
      const ERROR = logicFolderErrorMessages.logicJumpRuleError;
      let isAlreadyExist = errorsList.some(err => err.id === ERROR.id);
      if (!isAlreadyExist) errorsList.push({ id: ERROR.id, msg: ERROR.msg });
    }
    return { isError: isErrorFlag, msg: errorsList };
  }

  /**
   * Function for update logic set index mapped value
   * useful when drag and drop is happend then check if deadlock set is there or not
   * when logic set is deleted then set End quiz for mapped deleted set
   * @param tempLogicSetData logic set data
   * @param deletedIndex positive value number, value === -1 then it is not a delete operation
   * @returns updated logic set data
   */
  updateLogicSetIndexOnUpdate(tempLogicSetData: logicSetSchema[], deletedIndex = -1) {
    console.log(deletedIndex, tempLogicSetData);
    tempLogicSetData.forEach((eachLogicSet, logicSetIndex: number) => {
      //// FIRST NEED TO CHECK FOR LOGIC SET LOGIC JUMP RULE
      eachLogicSet.logicJumps.rules.forEach((eachSetRule) => {
        if(deletedIndex === -1) {
          /// JUMP CONDITION SETINDEX CHECK WITH CURRENT INDEX IF SAME CLEAR THE VALUE ///
          if(eachSetRule.thenBlock.jumpCondition.setIndex == logicSetIndex) {
            eachSetRule.thenBlock.jumpCondition.setIndex = '';
          }
        }
        else {
          /// JUMP CONDITION SETINDEX CHECK WITH DELETED INDEX IF SAME SET END QUIZ VALUE ///
          if(eachSetRule.thenBlock.jumpCondition.setIndex == deletedIndex) {
            eachSetRule.thenBlock.jumpCondition.setIndex = '-1';
          }
        }
      })
      if(deletedIndex === -1) {
        /// ALWAYSBLOCK CONDITION SETINDEX CHECK WITH CURRENT INDEX IF SAME CLEAR THE VALUE ///
        if(eachLogicSet.logicJumps.alwaysBlock.setIndex == logicSetIndex) {
          eachLogicSet.logicJumps.alwaysBlock.setIndex = '';
        }
      }
      else {
        /// ALWAYSBLOCK CONDITION SETINDEX CHECK WITH DELETED INDEX IF SAME SET END QUIZ VALUE ///
        if(eachLogicSet.logicJumps.alwaysBlock.setIndex == deletedIndex) {
          eachLogicSet.logicJumps.alwaysBlock.setIndex = '-1';
        }
      }
      const logicJumpError = this.checkLogicJumpFormError(eachLogicSet.logicJumps);
      eachLogicSet.logicJumps.error = logicJumpError.isError;
      eachLogicSet.logicJumps.error_obj = logicJumpError;
      const logicSetError = this.checkLogicSetError(eachLogicSet);
      eachLogicSet.error = logicSetError.isError;
      eachLogicSet.error_obj = logicSetError;
    });
    return tempLogicSetData;
  }

  /**
   * Function for update logic section index mapped value
   * useful when a new section added or a section is deleted
   * @param tempLogicSectionData logic sections data
   * @param mode UPDATE | DELETE
   * @param index deleted index
   * @returns updated logic sections data
   */
  updateLogicSectionIndexOnUpdate(tempLogicSectionData: logicSectionSchema[], mode: string, index: number) {
    tempLogicSectionData.forEach((eachLogicSection, logicSectionIndex: number) => {
      //// CHECK IF ALL SECTION DATA IS AVAILABLE
      if(eachLogicSection.allsections.length) {
        //// IF LOGIC JUMP ALWAYS BLOCK QUESTION INDEX HAS NON EMPTY VALUE
        if(!this.checkEmptyValue(eachLogicSection.allsections[0].logicJumps.alwaysBlock.questionIndex)) {
          const qIndex = parseInt(eachLogicSection.allsections[0].logicJumps.alwaysBlock.questionIndex.toString());
          //// CHECK END QUIZ IS NOT CONFIGURED
          if(qIndex >= 0) {
            if (mode === 'UPDATE') {
              console.log('QIndex: ', qIndex, 'Index: ', index);
              /// INCREASE THE SECTION BY 1 FROM THE UPDATED INDEX
              if(qIndex >= index) {
                eachLogicSection.allsections[0].logicJumps.alwaysBlock.questionIndex = qIndex + 1;
              }
            }
            if (mode === 'DELETE') {
              console.log('QIndex: ', qIndex, 'Index: ', index);
              /// SET QUESTION INDEX TO END QUIZ WHEN SAME SECTION IS DELETED
              if(qIndex == index) {
                eachLogicSection.allsections[0].logicJumps.alwaysBlock.questionIndex = '-1';
              }
              /// DECREASE QUESTION INDEX BY ONE FROM THE DELETED INDEX
              else if(qIndex > index) {
                eachLogicSection.allsections[0].logicJumps.alwaysBlock.questionIndex = qIndex - 1;
              }
            }
          }
        }
        if(eachLogicSection.allsections[0].logicJumps.rules.length) {
          eachLogicSection.allsections[0].logicJumps.rules.forEach((eachRule) => {
            //// IF RULE JUMP CONDITION BLOCK QUESTION INDEX HAS NON EMPTY VALUE
            if (!this.checkEmptyValue(eachRule.thenBlock.jumpCondition.questionIndex)) {
              const qIndex = parseInt(eachRule.thenBlock.jumpCondition.questionIndex.toString());
              if (qIndex >= 0) {
                if (mode === 'UPDATE') {
                  console.log('QIndex: ', qIndex, 'Index: ', index);
                  /// INCREASE THE SECTION BY 1 FROM THE UPDATED INDEX
                  if (qIndex >= index) {
                    eachRule.thenBlock.jumpCondition.questionIndex = qIndex + 1;
                  }
                }
                if (mode === 'DELETE') {
                  console.log('QIndex: ', qIndex, 'Index: ', index);
                  /// SET QUESTION INDEX TO END QUIZ WHEN SAME SECTION IS DELETED
                  if (qIndex == index) {
                    eachRule.thenBlock.jumpCondition.questionIndex = '-1';
                  }
                  /// DECREASE QUESTION INDEX BY ONE FROM THE DELETED INDEX
                  else if (qIndex > index) {
                    eachRule.thenBlock.jumpCondition.questionIndex = qIndex - 1;
                  }
                }
              }
            }
          })
        }
      }
      eachLogicSection.error = this.checkLogicSectionError(eachLogicSection);
    })
    return tempLogicSectionData;
  }

  /**
   * Get logic lesson from session storage
   * when logic folder developed then it will be deprecated
   * @returns logic lesson json data
   */
  getLogicLesson() {
    if (sessionStorage.getItem('logicalLessonData')) {
      let logicLesson = JSON.parse(sessionStorage.getItem('logicalLessonData'));
      return logicLesson;
    }
    else {
      return null;
    }
  }

  /**
   * Get perticular logic folder index by logic_folder_id from session storage
   * when logic folder developed then it will be deprecated
   * @param id logic_folder_id
   * @param modeValue modeValue 'CREATED' | 'DRAFT'
   * @returns matched index
   */
  getLogicFolderIndex(id: string, modeValue: string = 'CREATED') {
    let matchedLogicFolderIndex = -1;
    if (sessionStorage.getItem('logicalLessonData')) {
      let logicLesson = JSON.parse(sessionStorage.getItem('logicalLessonData'));
      matchedLogicFolderIndex = logicLesson.lesson_data.findIndex((elem: any) => {
        return elem.id == id && elem.mode == modeValue;
      }) 
    }
    this.currentLogicFolderIndex = matchedLogicFolderIndex;
    return matchedLogicFolderIndex;
  }

  /**
   * Function for get whole logic lessson data from session storage
   * when logic folder will be saved in db separately then not required
   */
  initLogicLessonData() {
    const logicLessonData = this.getLogicLesson();
    this.currentLogicLessonId = logicLessonData.id;
    this.logicLessonData = logicLessonData;
  }

  /**
   * Clear all Observable value
   */
  clearAllLogicData() {
    this.currentLogicLessonId = '';
    this.logicLessonData = null;
    this.logicFolderData.next(null);
    this.variableData.next([]);
    this.logicLeftSectionData.next([]);
    this.logicFolderConfigInfo = null;
    this.currentLogicSetData = [];
    this.perticularLogicSet = null;
    this.logicSectionData.next([]);
    this.leftSectionMappingUpdated.next([]);
    this.activeLeftSectionIndex.next(0);
    this.activeLogicSetIndex.next(0);
    this.activeLogicSectionIndex.next(0);
  }

  /**
   * Function used for retreive perticular logic folder data from session storage and save STATE
   * after api implementation, this function will be deprecated
   * @param logic_folder_id logic folder id to be found
   * @param modeValue type of logic folder to retrieve
   */
  initCurrentLogicFolderData(logic_folder_id: string, modeValue: string = 'CREATED') {
    this.getLogicFolderIndex(logic_folder_id, modeValue);
    const currentLogicFolderData = this.currentLogicFolderIndex >= 0 ? this.logicLessonData.lesson_data[this.currentLogicFolderIndex] : null;
    this.currentLogicFolderId = currentLogicFolderData ? currentLogicFolderData.id : null;
    this.logicFolderData.next(currentLogicFolderData);
    this.variableData.next(currentLogicFolderData ? currentLogicFolderData.variablesList : []);
    this.logicLeftSectionData.next(currentLogicFolderData ? currentLogicFolderData.left_section_data : []);
    this.activeLeftSectionIndex.next(0);
    this.activeLogicSetIndex.next(0);
    this.logicFolderConfigInfo = currentLogicFolderData ? currentLogicFolderData.config_info : null;
    this.currentLogicSetData = currentLogicFolderData ? currentLogicFolderData.logic_set : [];
  }

  /**
   * Get current logic set and index
   * also set state data from current all logic section data
   * @param logic_set_id id
   * @returns logic sections data
   */
  getCurrentLogicSectionDataBySetId(logic_set_id: string) {
    this.perticularLogicSet = this.currentLogicSetData.find((eachLogicSet: any) => {
      return eachLogicSet.id == logic_set_id;
    });
    this.currentLogicSetIndex = this.currentLogicSetData.findIndex((eachLogicSet: any) => {
      return eachLogicSet.id == logic_set_id;
    });
    this.logicSectionData.next(this.perticularLogicSet.sections);
    return this.perticularLogicSet.sections;
  }

  /**
   * get logic jump details data for a perticular logic section by index
   * @param logicSectionIndex index of current active section
   * @returns logic jump data
   */
  getLogicJumpForSpecificLogicSection(logicSectionIndex: number): logicJumpsSchema|null {
    const tempLogicSectionData = [...this.logicSectionData.value];
    if(tempLogicSectionData[logicSectionIndex].allsections[0].logicJumps) {
      return tempLogicSectionData[logicSectionIndex].allsections[0].logicJumps;
    }
    return null;
  }

  /**
   * get logic jump details data for a perticular logic set by index
   * @param logicSetIndex index of current active set
   * @returns logic jump data
   */
  getLogicJumpForSpecificLogicSet(logicSetIndex: number): logicJumpsSchema|null {
    const tempLogicSetData = [...this.currentLogicSetData];
    if(tempLogicSetData[logicSetIndex].logicJumps) {
      return tempLogicSetData[logicSetIndex].logicJumps;
    }
    return null;
  }

  /**
   * get callback from branching modal after click on SAVE button of the logic section
   * update logicFolder, logicSection state value
   * check logic section, logic set, logic folder error
   * call api for save data
   * @param logicJumpData data for update
   * @param logicSectionIndex current section index
   */
  updateLogicJumpDataForLogicSection(logicJumpData: any, logicSectionIndex: number) {
    const tempLogicSectionData = [...this.logicSectionData.value];
    const currentLogicFolderData = this.logicFolderData.value;
    tempLogicSectionData[logicSectionIndex].allsections[0].logicJumps = logicJumpData;

    /// LOGIC SECTION ERROR ///
    tempLogicSectionData[logicSectionIndex].error = this.checkLogicSectionError(tempLogicSectionData[logicSectionIndex]);
    currentLogicFolderData.logic_set[this.currentLogicSetIndex].sections = [...tempLogicSectionData];

    /// LOGIC SET ERROR ///
    const logicSetErrorData = this.checkLogicSetError(currentLogicFolderData.logic_set[this.currentLogicSetIndex]);
    currentLogicFolderData.logic_set[this.currentLogicSetIndex].error = logicSetErrorData.isError
    currentLogicFolderData.logic_set[this.currentLogicSetIndex].error_obj = logicSetErrorData;
    currentLogicFolderData.updated_by = this.authUser.user.id;

    /// LOGIC FOLDER ERROR ///
    const errorsData = this.checkLogicFolderError(this.logicLeftSectionData.value, this.currentLogicSetData);
    currentLogicFolderData.error = errorsData.isError;
    currentLogicFolderData.error_obj = errorsData;
    this.logicLessonData.lesson_data.splice(this.currentLogicFolderIndex, 1, currentLogicFolderData);
    this.logicFolderData.next(currentLogicFolderData);
    this.logicSectionData.next(tempLogicSectionData);
    this.saveLogicFolderData('updateLogicJumpDataForLogicSection', this.logicLessonData);
  }

  /**
   * get callback from branching modal after click on SAVE button of the logic set
   * update logicFolder, logicSet state value
   * check logic section, logic set, logic folder error
   * call api for save data
   * @param logicJumpData data to update
   * @param logicSetIndex current set index
   */
  updateLogicJumpDataForLogicSet(logicJumpData: any, logicSetIndex: number) {
    const currentLogicFolderData = this.logicFolderData.value;
    currentLogicFolderData.logic_set[logicSetIndex].logicJumps = logicJumpData;
    console.log(currentLogicFolderData);
    
    /// LOGIC SET ERROR ///
    const logicSetErrorData = this.checkLogicSetError(currentLogicFolderData.logic_set[logicSetIndex]);
    currentLogicFolderData.logic_set[logicSetIndex].error = logicSetErrorData.isError
    currentLogicFolderData.logic_set[logicSetIndex].error_obj = logicSetErrorData;
    currentLogicFolderData.updated_by = this.authUser.user.id;

    this.currentLogicSetData = currentLogicFolderData.logic_set;
    this.perticularLogicSet = currentLogicFolderData.logic_set[logicSetIndex];
    console.log(this.currentLogicSetData);

    /// LOGIC FOLDER ERROR ///
    const errorsData = this.checkLogicFolderError(this.logicLeftSectionData.value, this.currentLogicSetData);
    currentLogicFolderData.error = errorsData.isError;
    currentLogicFolderData.error_obj = errorsData;
    this.logicLessonData.lesson_data.splice(this.currentLogicFolderIndex, 1, currentLogicFolderData);
    this.logicFolderData.next(currentLogicFolderData);
    this.saveLogicFolderData('updateLogicJumpDataForLogicSet', this.logicLessonData);
  }

  /**
   * get callback from variable modal after click on SAVE button
   * update logicFolder state data and call api for save
   * @param variableList update variableslist
   */
  updateVariableForLogicFolder(variableList: variableSchema[]) {    
    this.variableData.next(variableList);
    const currentLogicFolderData = this.logicFolderData.value;
    currentLogicFolderData.variablesList = variableList;
    currentLogicFolderData.updated_by = this.authUser.user.id;
    this.logicLessonData.lesson_data.splice(this.currentLogicFolderIndex, 1, currentLogicFolderData);
    this.logicFolderData.next(currentLogicFolderData);
    this.saveLogicFolderData('updateVariableForLogicFolder', this.logicLessonData);
  }

  /**
   * function for update left section data for logic folder on insert or delete
   * @param leftSectionData left section data in logic folder
   * @param updatedSectionIndex deleted or updated index
   * @param mode INSERT | DELETE
   */
  updateLeftSectionDataForLogicFolder(leftSectionData: logicSetLeftSectionSchema[], updatedSectionIndex: number = -1, mode: string = 'INSERT') {
    this.logicLeftSectionData.next(leftSectionData);
    const currentLogicFolderData = this.logicFolderData.value;
    currentLogicFolderData.left_section_data = leftSectionData;
    if(updatedSectionIndex > -1) {
      currentLogicFolderData.logic_set = this.updateLeftSectionMappingOnAction(updatedSectionIndex, mode);
      this.perticularLogicSet = currentLogicFolderData.logic_set[this.currentLogicSetIndex];
      this.leftSectionMappingUpdated.next(this.perticularLogicSet.sections);
    }
    currentLogicFolderData.updated_by = this.authUser.user.id;

    /// LOGIC FOLDER ERROR ///
    const errorsData = this.checkLogicFolderError(this.logicLeftSectionData.value, this.currentLogicSetData);
    currentLogicFolderData.error = errorsData.isError;
    currentLogicFolderData.error_obj = errorsData;
    this.logicLessonData.lesson_data.splice(this.currentLogicFolderIndex, 1, currentLogicFolderData);
    this.logicFolderData.next(currentLogicFolderData);
    this.saveLogicFolderData('updateLeftSectionDataForLogicFolder', this.logicLessonData);
  }

  /**
   * Function for update compulsory | status | logic folder config data for logic folder
   * @param data any
   * @param type compulsory | status | config
   */
  updateSettingsForLogicFolder(data: any, type: string) {
    let currentLogicFolderData = this.logicFolderData.value;
    if(type === 'compulsory') {
      currentLogicFolderData.compulsory = data;
      currentLogicFolderData.config_info.compulsory = data;
    }
    else if(type === 'status') {
      currentLogicFolderData.status = data;
    }
    else if(type === 'config') {
      currentLogicFolderData = Object.assign(currentLogicFolderData, ...data);
      currentLogicFolderData.config_info = data;
      currentLogicFolderData.mode = 'CREATED';
    }
    currentLogicFolderData.updated_by = this.authUser.user.id;
    this.logicLessonData.lesson_data.splice(this.currentLogicFolderIndex, 1, currentLogicFolderData);
    this.logicFolderData.next(currentLogicFolderData);
    this.saveLogicFolderData('updateSettingsForLogicFolder', this.logicLessonData);
  }

  /**
   * function for update logic set data for logic folder
   * @param logicSetData all logic set data
   * @param type SINGLE | RESET_LIST | DRAG_DROP_LIST
   * @param setIndex new index for set after drag drop
   */
  updateLogicSetForLogicFolder(logicSetData: logicSetSchema[], type: string = 'SINGLE', setIndex: number = 0) {
    const currentLogicFolderData = this.logicFolderData.value;
    if(type === 'RESET_LIST' || type === 'DRAG_DROP_LIST') {
      currentLogicFolderData.logic_set = [];  
    }
    currentLogicFolderData.logic_set = currentLogicFolderData.logic_set.concat(logicSetData);
    this.currentLogicSetData = currentLogicFolderData.logic_set;
    currentLogicFolderData.updated_by = this.authUser.user.id;
    currentLogicFolderData.num_of_logic_sets = currentLogicFolderData.logic_set.length;
    currentLogicFolderData.num_of_sections = this.getTotalSections();
    const errorsData = this.checkLogicFolderError(this.logicLeftSectionData.value, this.currentLogicSetData);
    currentLogicFolderData.error = errorsData.isError;
    currentLogicFolderData.error_obj = errorsData;
    this.logicLessonData.lesson_data.splice(this.currentLogicFolderIndex, 1, currentLogicFolderData);
    this.logicFolderData.next(currentLogicFolderData);
    if(type === 'DRAG_DROP_LIST') {
      this.activeLogicSetIndex.next(setIndex);
    }
    else {
      this.activeLogicSetIndex.next(this.currentLogicSetData.length - 1);
    }
    this.saveLogicFolderData('updateLogicSetForLogicFolder', this.logicLessonData);
  }

  /**
   * delete logic set
   * @param index index to delete
   */
  removeSpecificLogicSetofLogicFolder(index: number) {
    const currentLogicFolderData = this.logicFolderData.value;
    currentLogicFolderData.logic_set.splice(index, 1);
    this.currentLogicSetData = [...this.updateLogicSetIndexOnUpdate(currentLogicFolderData.logic_set, index)];
    currentLogicFolderData.logic_set = this.currentLogicSetData;
    currentLogicFolderData.updated_by = this.authUser.user.id;
    currentLogicFolderData.num_of_logic_sets = currentLogicFolderData.logic_set.length;
    currentLogicFolderData.num_of_sections = this.getTotalSections();
    const errorsData = this.checkLogicFolderError(this.logicLeftSectionData.value, this.currentLogicSetData);
    currentLogicFolderData.error = errorsData.isError;
    currentLogicFolderData.error_obj = errorsData;
    this.logicLessonData.lesson_data.splice(this.currentLogicFolderIndex, 1, currentLogicFolderData);
    this.logicFolderData.next(currentLogicFolderData);
    this.activeLogicSetIndex.next(index - 1);
    this.saveLogicFolderData('removeSpecificLogicSetofLogicFolder', this.logicLessonData);
  }

  /**
   * ADD _ REMOVE _ DUPLICATE _ IMPORT WHOLE SECTION VALUE IN THE LOGIC SET
   * THEN CHECK FOR CURRENT LOGIC SET ERROR
   * THEN CHECK FOR CURRENT LOGIC FOLDER ERROR
   * @param sectionData updated sectiondata
   * @param updatedSectionIndex section index to change
   */
  updateSectionDataForSpecificLogicSetOfLogicFolder(sectionData: logicSectionSchema[], updatedSectionIndex: number = -1) {
    this.dataService.setLoadingStatus(true);
    const currentLogicFolderData = this.logicFolderData.value;
    this.perticularLogicSet.sections = sectionData;
    this.logicSectionData.next(sectionData);
    
    /// LOGIC SET ERROR ///
    const logicSetErrorData = this.checkLogicSetError(this.perticularLogicSet);
    this.perticularLogicSet.error = logicSetErrorData.isError;
    this.perticularLogicSet.error_obj = logicSetErrorData;
    this.currentLogicSetData[this.currentLogicSetIndex] = this.perticularLogicSet;

    // IT DETERMINES SECTION HAS BEEN REMOVED
    if(updatedSectionIndex > -1) {
      // currentLogicFolderData.logic_set = this.updateLeftSectionMappingOnAction(updatedSectionIndex);
    }
    currentLogicFolderData.updated_by = this.authUser.user.id;
    currentLogicFolderData.num_of_sections = this.getTotalSections();
    
    /// LOGIC FOLDER ERROR ///
    const errorsData = this.checkLogicFolderError(this.logicLeftSectionData.value, this.currentLogicSetData);
    currentLogicFolderData.error = errorsData.isError;
    currentLogicFolderData.error_obj = errorsData;

    this.logicLessonData.lesson_data.splice(this.currentLogicFolderIndex, 1, currentLogicFolderData);
    this.logicFolderData.next(currentLogicFolderData);
    this.saveLogicFolderData('updateSectionDataForSpecificLogicSetOfLogicFolder', this.logicLessonData);
  }

  /**
   * UPDATE SUB SECTION DATA FOR LOGIC SET (QUESTION | SOLUTION | TEXT | VIDEO)
   * THEN CHECK FOR CURRENT LOGIC SET ERROR
   * THEN CHECK FOR CURRENT LOGIC FOLDER ERROR
   * @param subSectionData sub section data to update
   * @param updatedSectionIndex 
   */
  updateSubSectionDataForSpecificLogicSetOfLogicFolder(subSectionData: logicSubSectionSchema, mode: string = 'CREATE_UPDATE') {
    const currentLogicFolderData = this.logicFolderData.value;
    if(mode === 'CREATE_UPDATE') {
      this.perticularLogicSet.sections[this.activeLogicSectionIndex.value].allsections = [subSectionData];
    }
    else {
      this.perticularLogicSet.sections[this.activeLogicSectionIndex.value].allsections = [];
    }
    this.perticularLogicSet.sections[this.activeLogicSectionIndex.value].error = this.checkLogicSectionError(this.perticularLogicSet.sections[this.activeLogicSectionIndex.value]);
    
    /// LOGIC SET ERROR ///
    const logicSetErrorData = this.checkLogicSetError(this.perticularLogicSet);
    this.perticularLogicSet.error = logicSetErrorData.isError;
    this.perticularLogicSet.error_obj = logicSetErrorData;
    this.currentLogicSetData[this.currentLogicSetIndex] = this.perticularLogicSet;
    currentLogicFolderData.logic_set = this.currentLogicSetData;
    
    // if(updatedSectionIndex > -1) {
    //   currentLogicFolderData.logic_set = this.updateLeftSectionMappingOnAction(updatedSectionIndex);
    // }
    currentLogicFolderData.updated_by = this.authUser.user.id;
    currentLogicFolderData.num_of_sections = this.getTotalSections();

    /// LOGIC FOLDER ERROR ///
    const errorsData = this.checkLogicFolderError(this.logicLeftSectionData.value, this.currentLogicSetData);
    currentLogicFolderData.error = errorsData.isError;
    currentLogicFolderData.error_obj = errorsData;
    
    this.logicLessonData.lesson_data.splice(this.currentLogicFolderIndex, 1, currentLogicFolderData);
    this.logicFolderData.next(currentLogicFolderData);
    this.saveLogicFolderData('updateSubSectionDataForSpecificLogicSetOfLogicFolder', this.logicLessonData);
  }

  /**
   * Specialy useful for after variable delete need to search whole logicFolder for trace of that variable
   */
  updateWholeLogicFolderData(logicFolderDataUpdate: logicFolderSchema) {
    const currentLogicFolderData = logicFolderDataUpdate;
    this.logicFolderData.next(currentLogicFolderData);
    this.variableData.next(currentLogicFolderData ? currentLogicFolderData.variablesList : []);
    this.logicLeftSectionData.next(currentLogicFolderData ? currentLogicFolderData.left_section_data : []);
    this.logicFolderConfigInfo = currentLogicFolderData ? currentLogicFolderData.config_info : null;
    this.currentLogicSetData = currentLogicFolderData ? currentLogicFolderData.logic_set : [];
    if(this.currentLogicSetIndex >= 0) {
      this.perticularLogicSet = this.currentLogicSetData[this.currentLogicSetIndex];
      this.logicSectionData.next(this.perticularLogicSet.sections);
    }
    this.logicLessonData.lesson_data.splice(this.currentLogicFolderIndex, 1, currentLogicFolderData);
    this.saveLogicFolderData('updateSectionDataForSpecificLogicSetOfLogicFolder', this.logicLessonData);
  }

  /**
   * function for update active left section index
   * @param index active index value
   */
  updateActiveLeftSectionIndex(index: number) {
    this.activeLeftSectionIndex.next(index);
  }

  /**
   * function for update active logic set index
   * @param index active index value
   */
  updateActiveLogicSetIndex(index: number) {
    this.activeLogicSetIndex.next(index);
  }

  /**
   * function for update active logic section index
   * @param index active index value
   */
  updateActiveLogicSectionIndex(index: number) {
    this.activeLogicSectionIndex.next(index);
  }

  /**
   * Function for call api and update logiclesson state data
   * update sessionStorage and loading status false
   * @param type pre redirection function
   * @param json_object update json data 
   */
  saveLogicFolderData(type: string, json_object: any) {
    console.log(`saveLogicFolderData called from ${type} function. ${this.currentLogicLessonId}`, json_object);
    this.editLogicalLessonData(this.currentLogicLessonId, json_object).subscribe(
      (response: any) => {
        // console.log(response)
        // this.logicLessonData.next(response);
        sessionStorage.setItem('logicalLessonData', JSON.stringify(response));
        this.dataService.setLoadingStatus(false);
      }, (error: any) => {
        this.dataService.setLoadingStatus(false);
      }
    )    
  }

}
