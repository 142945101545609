import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {


  constructor() { }


  /**
   * @description {{ this will store the status for loading (true/fasle) whenever the API gets hitted }}
  */
  public isLoading = new BehaviorSubject<boolean>(false);
  IsLoading = this.isLoading.asObservable();

  setLoadingStatus(isLoading: boolean) {
    this.isLoading.next(isLoading);
  }



  /**
   * @description {{ this will store the Question-Folder (Main-Question) filter like (importance/skills/marks etc) values for the filtration }}
  */
  public filterValue = new BehaviorSubject<any>(null);
  filterValueStr = this.filterValue.asObservable();

  setFilterValue(searchObj: any) {
    this.filterValue.next(searchObj);
  }



  /**
   * @description {{ this will store all the Question-Folders (Main-Questions) }}
  */
  public SECTIONS = new BehaviorSubject<any[]>([]);
  sections = this.SECTIONS.asObservable();

  storeAllSections(sections: any[], status: string) {
    console.log('store all sections for flow-graph...' + status)
    this.SECTIONS.next(sections);
  }


  /**
   * @description {{ this will store update of any thing in the project }}
  */
  UptadeOBJ = {
    updated: false,
    type: null,
    questionType: null
  }
  public UPDATEOBJ = new BehaviorSubject<object>(this.UptadeOBJ);
  update = this.UPDATEOBJ.asObservable();

  storeAllUpdate(UptadeOBJ: object) {
    console.log('store UptadeOBJ for flow-graph...', { UptadeOBJ })
    this.UPDATEOBJ.next(UptadeOBJ);
  }



  /**
    @description {{ 
                    * store the source node and the target node of the flow-diagram in an array format 
                    * If type === 'TEXT' OR 'SOLUTION'
                    * index 0 ==> source node
                    * index 1 ==> target node
                    * If type === 'QUESTION'
                    * index 0 ==> source node
                    * index 1 ==> target node
                    * index 2 ==> source node
                    * index 3 ==> target node
                 }}
  **/
  public sourceTargetArr = new BehaviorSubject<any[]>([]);
  SourceTargetArr = this.sourceTargetArr.asObservable();

  storeSourceTargetArr(SourceTargetArr: any[], status: string) {
    console.log('store source target array for flow-graph...' + status, { SourceTargetArr });
    this.sourceTargetArr.next(SourceTargetArr);
  }


  public COLLAPSE_LEFT_PANEL = new BehaviorSubject<boolean>(false);
  collapse_left_panel = this.COLLAPSE_LEFT_PANEL.asObservable();

  isCollapseLeftPanel(collapse_left_panel: boolean) {
    this.COLLAPSE_LEFT_PANEL.next(collapse_left_panel);
  }


  /**
   * @description {{ store total unread message count }}
  */
  public UnreadMsgCount = new BehaviorSubject<number>(0);
  UnreadMsgCount$ = this.UnreadMsgCount.asObservable();

  /* set number of unread messages */
  setUnreadMsgCount(count: number) {
    console.log({count});
    this.UnreadMsgCount.next(count);
  }



  /**
   * @description {{ store total unread conversations count }}
  */
  public UnreadConvCount = new BehaviorSubject<number>(0);
  UnreadConvCount$ = this.UnreadConvCount.asObservable();

  /* set number of unread conversations */
  setUnreadConversationCount(count: number) {
    this.UnreadConvCount.next(count);
  }


}
