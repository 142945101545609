import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';

@Directive({
    selector: '[ResizeBar]'
})

export class ResizeBarDirective implements AfterViewInit {

    ngAfterViewInit() {
        const BORDER_SIZE = 4;
        const panel = document.getElementById("right_panel");
        const mainPanel = document.getElementById("mainPanel");

        let m_pos;
        function resize(e) {
            const dx = m_pos - e.x;
            m_pos = e.x;
            panel.style.width = (parseInt(getComputedStyle(panel, '').width) + dx) + "px";
        }

        panel.addEventListener("mousedown", function (e) {
            if (e.offsetX < BORDER_SIZE) {
                m_pos = e.x;
                document.addEventListener("mousemove", resize, false);
                mainPanel.classList.add("sel-disabled");
            }
        }, false);

        document.addEventListener("mouseup", function () {
            document.removeEventListener("mousemove", resize, false);
            mainPanel.classList.remove("sel-disabled");
        }, false);

    }
}