import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CommonService } from '../services/APIService/common.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
        private route: ActivatedRoute,
        private commonService: CommonService,
        private location: Location) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        this.router.events.subscribe((value) => {
            if (value instanceof NavigationEnd) {
                let QuestionFolder = this.commonService.getQuestionFolder();;
                let Collection = JSON.parse(sessionStorage.getItem('Collection'));
                let currentUrlArr = (this.router.url.split('?')[0]).split('/');

                const subject_id = this.route.snapshot.queryParams['subject_id'];
                const lecture_id = this.route.snapshot.queryParams['lecture_id'];
                const quiz_bank_id = this.route.snapshot.queryParams['quiz_bank_id'];
                const question_set_id = this.route.snapshot.queryParams['question_set_id'];

                console.log('Requested URL ==> ', this.router);
                console.log('Current URL ==> ', currentUrlArr);

                if (((currentUrlArr[currentUrlArr.length - 1] === 'test-quiz' && !Collection) || (currentUrlArr[currentUrlArr.length - 1] === 'create-quiz' && !QuestionFolder))) {
                    this.router.navigate(['/subject/add-subject']);
                }

            }

            return true;
        });




        if (localStorage.getItem('CurrentUser')) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/teachers']);
        return false;



    }
}