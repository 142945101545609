export interface variableSchema {
    id: string;
    startValue: string|number;
    variableName: string;
    variableType: string;
}

export interface logicJumpsSchema {
    type?: 'SECTION'|'SET'; ///// CURRENTLY LOGIC JUMP CAN BE CONFIGURED FOR TWO TYPES THING
    rules: logicJumpRuleSchema[],
    alwaysBlock: {
        questionIndex?: string|number; ///// IT IS VALID FOR TYPE "SECTION"
        setIndex?: string|number; ///// IT IS VALID FOR TYPE "SET"
    },
    error: boolean;
    error_obj: {
        isError: boolean;
        msg: string[];
    };
}

export interface logicJumpRuleSchema {
    trigger: string;
    thenBlock: logicJumpThenBlockSchema;
    conditions: logicJumpConditionSchema[];
}

export interface logicJumpConditionSchema {
    op: string;
    selectedType: string;
    selectedQuestionType: string;
    selectedValue: string|number;
    action: string;
    selectedOptionValue: string|number;
}

export interface LogicJumpThenArithmaticSchema {
    action: string;
    sourceVariable: string;
    destinationVariable: string;
    numericValue: string|number;
}

export interface logicJumpThenBlockSchema {
    jumpCondition: {
        questionIndex?: string|number;
        setIndex?: string|number;
    },
    arithmaticConditions: LogicJumpThenArithmaticSchema[]
}

export interface logicFolderSchema {
    id: string;
    LF_details: {
        id: string;
        number: string|number;
        type: string;
    };
    advanced_bot_message?: string;
    complexity?: number;
    compulsory?: boolean;
    estimated_time?: number;
    importance?: number;
    main_question_model?: string;
    main_question_type?: string;
    past_exam?: string;
    split_screen?: boolean;
    created_by: number;
    updated_by?: number;
    mode: string;
    num_of_logic_sets?: number;
    num_of_sections?: number;
    status?: number;
    error?: boolean;
    error_obj: {
        isError: boolean;
        msg: string[];
    };
    config_info: logicFolderConfigSchema;
    left_section_data: logicSetLeftSectionSchema[];
    logic_set: logicSetSchema[];
    variablesList: variableSchema[];
}

export interface logicSetLeftSectionSchema {
    id: string;
    type: string;
    content: string;
    error?: boolean;
}

export interface logicFolderConfigSchema {
    advanced_bot_message: string;
    complexity: number;
    compulsory: boolean;
    estimated_time: string|number;
    importance: number;
    main_question_model: string;
    main_question_type: string;
    past_exam: string;
    split_screen: boolean;
    exam_type?: string;
    school_id?: string;
    year?: number;
}

export interface logicSetSchema {
    id: string;
    name: string;
    type: string;
    sections: logicSectionSchema[];
    logicJumps: logicJumpsSchema,
    error?: boolean,
    error_obj?: {
        isError: boolean;
        msg: string[];
    };
}

export interface logicSectionSchema {
    error?: boolean;
    id: string;
    logic_set_id: string;
    name: string;
    type: string;
    allsections: logicSubSectionSchema[];
}

export interface logicSubSectionSchema {
    type: string;
    questionType?: string;
    questionCategory?: string;
    question?: logicQuestionSchema;
    options?: logicOptionSchema[];
    answers?: any[];
    logicJumps?: logicJumpsSchema;
    leftSectionIndex?: string|number|any[];
    is_case_sensitive?: boolean;
    title?: string;
    description?: string;
    videoLink?: string;
}

export interface logicQuestionSchema {
    id: string;
    textValue: string;
}

export interface logicOptionSchema {
    id: string;
    textValue: string;
    imageValue: string;
    scoreValue: string;
}

export const branchingConditions = [
    {
        id: 'IF',
        name: 'If',
        useFor: [ 'SET', 'SECTION' ],
        allowCondition: true,
        isQuestionBlock: true,
        isActionBlock: false,
        isThenBlock: true,
    },
    {
        id: 'CORRECT',
        name: 'Correct Answer',
        useFor: [ 'SECTION' ],
        allowCondition: false,
        isQuestionBlock: false,
        isActionBlock: true,
        isThenBlock: false,
    },
    {
        id: 'WRONG',
        name: 'Wrong Answer',
        useFor: [ 'SECTION' ],
        allowCondition: false,
        isQuestionBlock: false,
        isActionBlock: true,
        isThenBlock: false,
    },
    {
        id: 'ALWAYS',
        name: 'Always',
        useFor: [ 'SECTION' ],
        allowCondition: false,
        isQuestionBlock: false,
        isActionBlock: true,
        isThenBlock: false,
    },
]

export const actionsList = [
    {
        id: 'EQUAL',
        name: 'is equal to',
        actionTypes: [ 'QUESTION', 'VARIABLE' ],
        questionTypes: [ 'TDDQ', 'TFQ', 'MSQ', 'TEQ', 'MCQ', '' ]
    },
    {
        id: 'NOT_EQUAL',
        name: 'is not equal to',
        actionTypes: [ 'QUESTION', 'VARIABLE' ],
        questionTypes: [ 'TDDQ', 'TFQ', 'MSQ', 'TEQ', 'MCQ', '' ]
    },
    {
        id: 'BEGINS_WITH',
        name: 'begins with',
        actionTypes: [ 'QUESTION' ],
        questionTypes: [ 'TEQ', '' ]
    },
    {
        id: 'ENDS_WITH',
        name: 'ends with',
        actionTypes: [ 'QUESTION' ],
        questionTypes: [ 'TEQ', '' ]
    },
    {
        id: 'CONTAIN',
        name: 'contains',
        actionTypes: [ 'QUESTION' ],
        questionTypes: [ 'TEQ', '' ]
    },
    {
        id: 'NOT_CONTAIN',
        name: 'does not contain',
        actionTypes: [ 'QUESTION' ],
        questionTypes: [ 'TEQ', '' ]
    },
    {
        id: 'LESS_THAN',
        name: 'is less than',
        actionTypes: [ 'VARIABLE' ],
        questionTypes: [ '' ]
    },
    {
        id: 'GREATER_THAN',
        name: 'is greater than',
        actionTypes: [ 'VARIABLE' ],
        questionTypes: [ '' ]
    },
    {
        id: 'LESS_EQUAL',
        name: 'is less or equal to',
        actionTypes: [ 'VARIABLE' ],
        questionTypes: [ '' ]
    },
    {
        id: 'GREATER_EQUAL',
        name: 'is greater or equal to',
        actionTypes: [ 'VARIABLE' ],
        questionTypes: [ '' ]
    },
]

export const andOrCondition = [
    {
        id: 'AND',
        name: 'And',
    },
    {
        id: 'OR',
        name: 'Or',
    },
]

export const thenActionsList = [
    {
        id: 'ADD',
        name: 'Add'
    },
    {
        id: 'SUBTRACT',
        name: 'Subtract'
    },
    {
        id: 'MULTIPLY',
        name: 'Multiply'
    },
    {
        id: 'DIVIDE',
        name: 'Divide'
    },
]

export const logicFolderErrorMessages = {
    logicSectionError: { id: 'LF-100', msg: 'Error found in section' },
    logicSectionEmpty: { id: 'LF-200', msg: 'Section does not have any component' },
    stepNotDefined: { id: 'LF-300', msg: 'Step is not assigned' },
    logicSetEmptySection: { id: 'LF-400', msg: 'Logic set does not have any section' },
    duplicateSectionFound: { id: 'LF-500', msg: 'Duplicate sections found' },
    leftSectionEmpty: { id: 'LF-600', msg: 'Empty left section found' },
    logicSetJumpError: { id: 'LF-700', msg: 'Error found in logic jump' },
    logicJumpRuleError: { id: 'LF-700', msg: 'Error found in logic jump rule section' },
    logicJumpAlwaysBlockError: { id: 'LF-800', msg: 'Error found in logic jump always block section' },
    logicJumpConditionError: { id: 'LF-900', msg: 'Error found in logic jump condition section' },
    logicJumpThenError: { id: 'LF-1000', msg: `Error found in logic jump rule's then section` },
};