import { Directive, Input, Output, EventEmitter, SimpleChanges, OnInit, OnChanges } from '@angular/core';

@Directive({
    selector: '[appCollapse]',
    exportAs: 'appCollapse'
})
export class CollapseDirective implements OnInit, OnChanges {
    @Input('appCollapseValue')
    public isCollapsed = false;

    @Output()
    public onChangeStatus = new EventEmitter<boolean>();
    @Output()
    public onBeforeChangeStatus = new EventEmitter<boolean>();

    constructor() { }

    ngOnInit() {
        this.isCollapsed = false;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isCollapsed.firstChange) {
            return;
        }
        changes.isCollapsed.currentValue ? this.close() : this.open();
    }

    public open() {
        this.isCollapsed = false;
    }

    public close() {
        this.isCollapsed = true;
    }


    public toggle() {
        this.onBeforeChangeStatus.emit(this.isCollapsed);
        this.isCollapsed ? this.open() : this.close();
        this.onChangeStatus.emit(this.isCollapsed);
    }

}