import {
	AfterViewInit,
	ChangeDetectionStrategy, Component,

	HostBinding,
	Input, OnInit
} from '@angular/core';
import * as objectPath from 'object-path';
import { AuthService } from '../../../../core/services/APIService/auth.service';
import { CommonService } from '../../../../core/services/APIService/common.service';
import { DataService } from '../../../../core/services/dataService/data-service.service';
import { LayoutConfigService } from '../../../../core/services/layout-config.service';

@Component({
	selector: 'm-topbar',
	templateUrl: './topbar.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopbarComponent implements OnInit, AfterViewInit {
	@HostBinding('id') id = 'm_header_nav';
	@HostBinding('class')
	classes = 'm-stack__item m-stack__item--fluid m-header-head';

	@Input() searchType: any;

	constructor(
		private layoutConfigService: LayoutConfigService,
		public dataService: DataService,
		public authService: AuthService,
		public commonService: CommonService
	) {
		this.layoutConfigService.onLayoutConfigUpdated$.subscribe(model => {
			const config = model.config;
			this.searchType = objectPath.get(config, 'header.search.type');
		});
	}

	/* check for new or unread conversations and messages */
	getUnreadConvMsgCount() {
		this.authService.getAllUnreadCount().subscribe((response: any) => {

			if (response.isError) return;

			const totalUnreadConversations = response.total_unread_conversation ?
				response.total_unread_conversation :
				0;

			const totalUnreadMessages = response.total_unread_message ?
				response.total_unread_message :
				0;

			this.dataService.setUnreadConversationCount(totalUnreadConversations);
			this.dataService.setUnreadMsgCount(totalUnreadMessages);

		},
			error => console.error);
	}

	ngOnInit(): void {
		this.getUnreadConvMsgCount();
	}


	ngAfterViewInit(): void { }
}
