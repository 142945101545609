import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CommonService } from '../../../../../../../../../../core/services/APIService/common.service';
import { DataService } from '../../../../../../../../../../core/services/dataService/data-service.service';
import { environment } from '../../../../../../../../../../../environments/environment';

@Pipe({
  name: 'filterQuestions',
  pure: false
})

/* filterQuestions is use to filter only MCQ & TDDQ & MSQ & TEQ type of questions from the sections[] array 
   if filterType === 'FOR_OPTION' else filter all type of questions 
   (which one is received from the parent component) */
export class FilterMcqQuestions implements PipeTransform {
  transform(sections: any[], filterType: string): any {
    if (sections.length > 0) {
      if (filterType === 'FOR_OPTION') {
        return sections.filter(section => (section.allsections.length > 0 && section.allsections[0].type === 'QUESTION' && (section.allsections[0].questionType !== 'TFQ')));
      } else {
        return sections.filter(section => (section.allsections.length > 0 && section.allsections[0].type === 'QUESTION'));
      }
    }
  }
}


@Component({
  selector: 'm-explainer',
  templateUrl: './explainer.component.html',
  styleUrls: ['./explainer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExplainerComponent implements OnInit, OnDestroy {

  @Input('j') j;
  @Input('sections') set flow(sections: any[]) {
    this.sections = sections;
    if (!this.explainerSection) return;
    this.ExplainerForm.patchValue({
      LEFTSECTION_INDEX: this.explainerSection.leftSectionIndex,
      GOESTO: this.explainerSection.goesto === -1 ? this.explainerSection.goesto.toString() : this.explainerSection.goesto
    });
  };
  @Input('LEFTSECTIONS') set leftSelectedSection(leftSections) {
    this.leftSections = leftSections;
  };
  @Input('allsection') explainerSection;
  @Input('indexParent') indexParent;
  @Input('activeIndex') activeIndex;
  @Input('index_Section') index_Section;

  @Output('remove') remove = new EventEmitter<any>();
  @Output('passExplainerData') passExplainerData = new EventEmitter<any>();

  env = environment;
  imagePath = this.env.cdn_urls.image;

  ExplainerForm: FormGroup;

  explainer: any[] = [];
  sections: any = [];
  leftSections: any = [];

  quillEditorStyle = {
    'min-height': '100px',
    'background-color': '#fff'
  };
  allselected:boolean=false;


  public keypress$ = new Subject();
  private subscription1: Subscription;
  private subscription2: Subscription;

  public changes: boolean = false;
  unsubscribe$ = new Subject();
  public questionFolderConfigInfo: any;
  public questionFolderConfigSplitScreenInfo: any;

  constructor(
    private cd: ChangeDetectorRef,
    private commonService: CommonService,
    private dataService: DataService) {

    this.ExplainerForm = new FormGroup({
      LEFTSECTION_INDEX: new FormControl(['all']),
      EXPLAINER: new FormArray([]),
      GOESTO: new FormControl()
    })
  }

  get left_section_id() {
    return this.ExplainerForm.get('LEFTSECTION_INDEX');
  }

  get explainers() {
    return this.ExplainerForm.get('EXPLAINER')['controls'];
  }


  /* checks if there exist any error in this component or not */
  get isError() {

    if (this.ExplainerForm.invalid) return true;
    if (this.explainers && this.explainers.length === 0) return true;

    return false;
  }

  /* reset a particular question related information when the selection value is changed */
  reset(index) {
    let control = <FormArray>this.ExplainerForm.controls['EXPLAINER'];
    control.controls[index].patchValue({
      ANSWERID: null,
      CONDITION: null,
      INFORMATION: null,
    });

  }


  /* This function is use to return the question-type ( like MCQ/TDDQ/TEQ etc ) for each questions */
  getQuestionType(index) {
    let control = <FormArray>this.ExplainerForm.controls['EXPLAINER'];
    const SECTIONID = control.controls[index].get('SECTIONID').value;

    for (let i = 0; i < this.sections.length; i++) {
      if (this.sections[i].id === SECTIONID) {
        return (this.sections[i].allsections[0].questionType);
      }
    }

  }


  /* 
   This function is use to get question-data like(a EXPLAINER) 
   and make a single object and pass the object to the parent component 
  */

  getExplainerData(ACTION) {
    console.log(this.ExplainerForm);
    setTimeout(() => {
      if(!this.allselected && ACTION=='SET MAPPING FOR LEFT SECTION'){
      let leftsectArray:any=this.left_section_id.value;
      if(leftsectArray.length>0){
        leftsectArray = leftsectArray.filter(item=> {
          return item != 'all'
      })
      }
      this.ExplainerForm.controls.LEFTSECTION_INDEX.setValue(leftsectArray)
  
    }
    let control = <FormArray>this.ExplainerForm.controls['EXPLAINER'];
    let totaExplainer = control.controls.length;
    this.explainer = [];


    for (let i = 0; i < totaExplainer; i++) {
      let Explainer = {
        sectionId: control.controls[i].get('SECTIONID').value,
        answerId: control.controls[i].get('ANSWERID').value,
        conditionType: control.controls[i].get('CONDITION').value,
        information: control.controls[i].get('INFORMATION').value,
      }
      this.explainer.push(Explainer);

    }

    const EXPLAINER_OBJ = {
      type: this.explainerSection.type,
      explainers: this.explainer,
      leftSectionIndex: this.left_section_id.value,
      goesto: parseInt(this.ExplainerForm.get('GOESTO').value)
    }

    const SECTION_OBJ = {
      action: ACTION,
      section_data: EXPLAINER_OBJ,
      error: this.checkError()
    }

    setTimeout(() => {
      this.passExplainerData.emit(SECTION_OBJ);
      console.log('SECTION_OBJ', { SECTION_OBJ });
    }, 0);
  }, 200);
  }


  /* 
    pass the index to the parent component to perform delete,
    remove a perticuler sub-section that is (explainer) from a main-section
  */
  onRemove(index) {
    this.commonService.setQuestionSetAsEdited(false, this.indexParent, this.sections[this.indexParent].id);
    setTimeout(() => {
      this.remove.emit(index);
    }, 0);
  }


  /* initialise a new form-group for a new Explainer */
  initExplainer() {
    return new FormGroup({
      SECTIONID: new FormControl(null, [Validators.required]),
      ANSWERID: new FormControl(null, [Validators.required]),
      CONDITION: new FormControl(null),
      INFORMATION: new FormControl(null, [Validators.required]),
    });

  }

  /* this function add Explainer fields dynamically */
  addExplainer() {
    const control = <FormArray>this.ExplainerForm.controls['EXPLAINER'];
    control.push(this.initExplainer());
  }

  /* this function remove Explainer field dynamically */
  removeExplainer(INDEX) {
    const control = <FormArray>this.ExplainerForm.controls['EXPLAINER'];
    control.removeAt(INDEX);

    // setTimeout(() => {
    //   this.getExplainerData('REMOVE EXPLAINER');
    // }, 500);
  }




  /* checks if there exist any error in this component or not */
  checkError() {
    let control = <FormArray>this.ExplainerForm.controls['EXPLAINER'];
    let totaExplainer = control.controls.length;

    /* check if the there exist atleast one explainer */
    if (totaExplainer <= 0) {
      return true;
    }

    return false;
  }



  /* check for any changes within the form */
  checkForChanges(isUnsaved) {
    if (isUnsaved) this.changes = isUnsaved;
    this.ExplainerForm.valueChanges.pipe(
      tap(formValue => { if (!isUnsaved) this.compaireChanges(formValue) }),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => console.log(this.explainerSection))
  }

  /* compaire the changes */
  compaireChanges(formValue) {
    let EXPLAINERS = [];
    let totaExplainer = this.explainerSection.explainers ? this.explainerSection.explainers.length : 0;
    for (let i = 0; i < totaExplainer; i++) {
      EXPLAINERS.push({
        SECTIONID: this.explainerSection.explainers[i].sectionId,
        CONDITION: this.explainerSection.explainers[i].conditionType,
        ANSWERID: this.explainerSection.explainers[i].answerId,
        INFORMATION: this.explainerSection.explainers[i].information,
      });
    }
    const PREVIOUS = {
      LEFTSECTION_INDEX: this.explainerSection.leftSectionIndex,
      EXPLAINER: EXPLAINERS,
      GOESTO: this.explainerSection.goesto === -1 ? this.explainerSection.goesto.toString() : this.explainerSection.goesto
    };
    const LATEST = formValue;
    console.log({ PREVIOUS });
    console.log({ LATEST });
    this.changes = _.isEqual(PREVIOUS, LATEST);
    this.changes = !this.changes;
    this.commonService.setQuestionSetAsEdited(this.changes, this.indexParent, this.sections[this.indexParent].id);
    this.cd.markForCheck();
  }



  ngOnInit() {

    // this.subscription1 = this.keypress$
    //   .pipe(
    //     debounceTime(1500),
    //     distinctUntilChanged()
    //   ).subscribe((val: string) => {
    //     this.getExplainerData('AUTO SAVING SECTION');
    //   });

    this.questionFolderConfigInfo = this.commonService.getQuestionFolder().config_info;
    if(this.explainerSection.leftSectionIndex == undefined){
      this.explainerSection.leftSectionIndex=["all"]
    }
    if(this.explainerSection.leftSectionIndex != undefined){
      if(typeof this.explainerSection.leftSectionIndex!='object'){
        this.explainerSection.leftSectionIndex=this.explainerSection.leftSectionIndex.toString().split(',').map((eachIndex) => {
          if (eachIndex !== 'all') { 
            return +eachIndex 
          }
          return eachIndex;
        }); 
      }
    }

    if (this.questionFolderConfigInfo.split_screen == null) return null;
    this.questionFolderConfigSplitScreenInfo = this.questionFolderConfigInfo.split_screen;

    this.subscription2 = this.dataService.UPDATEOBJ.subscribe((UptadeOBJ: any) => {
      console.log({ UptadeOBJ });
      if (UptadeOBJ.sectionIndex === this.indexParent && UptadeOBJ.updated === true && UptadeOBJ.type === 'EXPLAINER') {
        this.ExplainerForm.patchValue({
          GOESTO: this.explainerSection.goesto === -1 ? this.explainerSection.goesto.toString() : this.explainerSection.goesto
        });

        let UptadeOBJ = {
          sectionIndex: null,
          updated: false,
          type: 'EXPLAINER',
          questionType: null
        }
        this.dataService.storeAllUpdate(UptadeOBJ);
        this.getExplainerData('SET PATH FOR SECTION');
      }
    });




    let control = <FormArray>this.ExplainerForm.controls['EXPLAINER'];
    let totaExplainer = this.explainerSection.explainers ? this.explainerSection.explainers.length : 0;

    for (let i = 0; i < totaExplainer; i++) {
      control.push(this.initExplainer());
      control.controls[i].patchValue({
        SECTIONID: this.explainerSection.explainers[i].sectionId,
        CONDITION: this.explainerSection.explainers[i].conditionType,
        ANSWERID: this.explainerSection.explainers[i].answerId,
        INFORMATION: this.explainerSection.explainers[i].information,
      });

    }

    this.ExplainerForm.patchValue({
      LEFTSECTION_INDEX: this.explainerSection.leftSectionIndex,
      GOESTO: this.explainerSection.goesto === -1 ? this.explainerSection.goesto.toString() : this.explainerSection.goesto
    });

    const isUnsaved = this.explainerSection.isUnsaved ? true : false;
    this.commonService.setQuestionSetAsEdited(isUnsaved, this.indexParent, this.sections[this.indexParent].id);
    this.checkForChanges(isUnsaved);
  }

  ngOnDestroy() {
    if (this.subscription1) this.subscription1.unsubscribe();
    if (this.subscription2) this.subscription2.unsubscribe();
  }

}