import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {
  public insertionAction: string = null;
  constructor() { }

  setInsertionAction(action) {
    this.insertionAction = action;
  }

  resetInsertionAction() {
    this.insertionAction = null;
  }

  getInsertionAction() {
    return this.insertionAction;
  }
}
