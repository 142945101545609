import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';

@Component({
  selector: 'm-ui-block-loader',
  template: `
    <div class="bg_mask bg_mask_N">
      <div class="leader_progress">
          <div class="progressnew indeterminate">
              <div class="indeterminate" style="width: 70%"></div>
          </div>
      </div>
    </div>
  `,
  styles: []
})
export class UiBlockLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    UiBlockLoaderComponent
  ],
  exports: [
    UiBlockLoaderComponent
  ],
  providers: [
    UiBlockLoaderComponent
  ]
})
export class UiBlockLoaderModule {

}
