import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'visibilityCheck'
})

export class VisibilityCheck implements PipeTransform {
    transform(value: any, args?: string): boolean {
        const PAGE_TYPE = args;
        switch (PAGE_TYPE) {
            case 'MAIN-TOPIC': {
                if (value.subtopic_info && value.subtopic_info.subtopics.length > 0) {
                    let visibility: boolean = value.subtopic_info.subtopics[0].visibility;
                    return visibility;
                } else {
                    return true;
                }
            }
            case 'SUB-TOPIC': {
                if (value.lecture_info && value.lecture_info.lect_data.length > 0) {
                    let visibility: boolean = value.lecture_info.lect_data[0].visibility;
                    return visibility;
                } else {
                    return true;
                }
            }
        }

    }
}
