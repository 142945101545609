import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getLectureId'
})

export class GetLectureId implements PipeTransform {
    transform(value: any, args?: string): number {
        const PAGE_TYPE = args;
        switch (PAGE_TYPE) {
            case 'MAIN-TOPIC': {
                let lecture_id: number = value.subtopic_info.subtopics[0].lecture_info.lect_data[0].id;
                return lecture_id;
            }
            case 'SUB-TOPIC': {
                let lecture_id: number = value.lecture_info.lect_data[0].id;
                return lecture_id;
            }
        }

    }
}
