import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ValidationService {
    headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private BASE_URL = environment.base_url;
    
    constructor(private http: HttpClient) { }

    checkParentUsername(parent_username: any) {
        let $OBJ = {
            username: parent_username
        }
        return this.http.post(`${this.BASE_URL}/chk_user/`, $OBJ, { headers: this.headers });
    }

    checkParentEmail(parent_email: any) {
        let $OBJ = {
            email: parent_email
        }
        return this.http.post(`${this.BASE_URL}/chk_email/`, $OBJ, { headers: this.headers });
    }

}
