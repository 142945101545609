import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'common-dialog',
    template: `
    <div class="commonModalHeader">
      <button class="close" mat-dialog-close>
        <i class="material-icons">close</i>
      </button>
      <h2 mat-dialog-title>{{data?.title}}</h2>
    </div>
    <mat-dialog-content class="common-dialog-content">
      <ng-container *ngComponentOutlet="data.component"></ng-container>
    </mat-dialog-content>
    <mat-dialog-actions class="commonModalFooter justify-content-center">
      <button class="btn btn-sm btn-purple min-w-80" (click)="dialogRef.close('cancel')">Cancel</button>
      <button class="btn btn-sm btn-success min-w-80 ml-2" (click)="dialogRef.close('ok')">Ok</button>
    </mat-dialog-actions>
    `
})

export class CommonDialog implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<CommonDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        console.log({ data });
    }

    ngOnInit() { }
}