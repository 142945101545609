import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  public testModuleEditedGroups: any[] = [];
  public questionSetEditedSections: any[] = [];
  public QFleftSections: any[] = [];
  public pages: any[] = [];
  public logicSetEditedSections: any[] = [];
  public LFleftSections: any = [];

  constructor(private http: HttpClient) { }

  /* check where the user is loggedIn or not */
  getAuthUser() {
    if (localStorage.getItem('CurrentUser')) {
      return JSON.parse(localStorage.getItem('CurrentUser'));
    } else {
      return null;
    }
  }

  getLogicLesson() {
    if (sessionStorage.getItem('logicalLessonData')) {
      let logicLesson = JSON.parse(sessionStorage.getItem('logicalLessonData'));
      return logicLesson;
    }
    else {
      return null;
    }
  }

  getLogicFolder(id: string, modeValue: string) {
    if (sessionStorage.getItem('logicalLessonData')) {
      let logicLesson = JSON.parse(sessionStorage.getItem('logicalLessonData'));
      let matchedLogicFolder = logicLesson.lesson_data.filter((elem: any) => {
        return elem.id == id && elem.mode == modeValue;
      })
      console.log(matchedLogicFolder);
      if (matchedLogicFolder.length > 0) {
        return matchedLogicFolder[0];
      } else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  getLogicFolderIndex(id: string, modeValue: string) {
    let matchedLogicFolderIndex = -1;
    if (sessionStorage.getItem('logicalLessonData')) {
      let logicLesson = JSON.parse(sessionStorage.getItem('logicalLessonData'));
      matchedLogicFolderIndex = logicLesson.lesson_data.findIndex((elem: any) => {
        return elem.id == id && elem.mode == modeValue;
      }) 
    }
    return matchedLogicFolderIndex;
  }

  /* check if the question-folder id exist in the sesssion-stroage 
     then return the entire question-folder-json data otherwise return null 
  */
  getQuestionFolder() {
    let QuestionFolder = sessionStorage.getItem('QuestionFolder');
    if (QuestionFolder && QuestionFolder.includes('id')) {
      return JSON.parse(QuestionFolder);
    } else {
      return null;
    }
  }


  /* check if the question-folder QF_details exist in the sesssion-stroage 
     then return the entire question-folder-details data (QF number and whether its a parent or child QF)
  */
  getQuestionFolderDetails() {
    let QuestionFolder = sessionStorage.getItem('QuestionFolder');
    if (QuestionFolder && QuestionFolder.includes('QF_details')) {
      return JSON.parse(QuestionFolder).QF_details;
    } else {
      return null;
    }
  }

  generateUniqueId() {
    return (Math.random().toString(36).substr(2, 9));
  }

  /* if any changes occured in the TEST-MODULE then add or remove those sections */
  setTestModuleAsEdited(isEdited: boolean, groupIndex, section_id: string, action: string = 'SECTION EDIT') {
    if (action === 'REMOVE GROUP') {
      this.testModuleEditedGroups.splice(groupIndex, 1);
      return;
    }
    if (!this.testModuleEditedGroups[groupIndex]) this.testModuleEditedGroups[groupIndex] = [];
    if (isEdited) {
      console.log(`groupIndex ${groupIndex}`, this.testModuleEditedGroups[groupIndex]);
      const index = this.testModuleEditedGroups[groupIndex] ? this.testModuleEditedGroups[groupIndex].indexOf(section_id) : -1;
      if (index === -1) this.testModuleEditedGroups[groupIndex].push(section_id);
      else this.testModuleEditedGroups[groupIndex].splice(index, 1, section_id);
    } else {
      console.log(`groupIndex ${groupIndex}`, this.testModuleEditedGroups[groupIndex]);
      const index = this.testModuleEditedGroups[groupIndex] ? this.testModuleEditedGroups[groupIndex].indexOf(section_id) : -1;
      if (index !== -1) this.testModuleEditedGroups[groupIndex].splice(index, 1);
    }
    console.log('unsaved sections => ', this.testModuleEditedGroups[groupIndex]);
  }


  /* check if any unsaved sections exist within a TEST-MODULE or not */
  public isTestModuleUnsaved() {
    console.log(this.testModuleEditedGroups);
    if (!(this.testModuleEditedGroups.length > 0)) return false;
    for (let group of this.testModuleEditedGroups)
      if (group && group.length > 0) return true;
    return false;
  }

  public initTestModule() {
    this.testModuleEditedGroups = [];
  }

  /* if any changes occured in the active QUESTION-SET then add or remove those sections */
  setQuestionSetAsEdited(isEdited: boolean, Index, id: string, action: string = 'SECTION EDIT') {
    if (action === 'REMOVE SECTION') {
      this.questionSetEditedSections.splice(Index, 1);
      return;
    }
    if (!this.questionSetEditedSections[Index]) this.questionSetEditedSections[Index] = [];
    if (isEdited) {
      const index = this.questionSetEditedSections[Index] ? this.questionSetEditedSections[Index].indexOf(id) : -1;
      if (index === -1) this.questionSetEditedSections[Index].push(id);
      else this.questionSetEditedSections[Index].splice(index, 1, id);
    } else {
      const index = this.questionSetEditedSections[Index] ? this.questionSetEditedSections[Index].indexOf(id) : -1;
      if (index !== -1) this.questionSetEditedSections[Index].splice(index, 1);
    }
    console.log('unsaved sections Q-SET => ', this.questionSetEditedSections[Index]);
  }

  /* check if any unsaved sections exist within the actvie QUESTION-SET or not */
  public isQuestionSetUnsaved() {
    console.log(this.questionSetEditedSections);
    if (!(this.questionSetEditedSections.length > 0)) return false;
    for (let section of this.questionSetEditedSections)
      if (section && section.length > 0) return true;
    return false;
  }

  public initQuestionSet() {
    this.questionSetEditedSections = [];
  }


  /* if any changes occured in the active LEFT-SECTION (for split screen) then add or remove those sections */
  setLeftSectionAsEdited(isEdited: boolean, Index, id: string, action: string = 'SECTION EDIT') {
    if (action === 'REMOVE SECTION') {
      this.QFleftSections.splice(Index, 1);
      return;
    }
    if (!this.QFleftSections[Index]) this.QFleftSections[Index] = [];
    if (isEdited) {
      const index = this.QFleftSections[Index] ? this.QFleftSections[Index].indexOf(id) : -1;
      if (index === -1) this.QFleftSections[Index].push(id);
      else this.QFleftSections[Index].splice(index, 1, id);
    } else {
      const index = this.QFleftSections[Index] ? this.QFleftSections[Index].indexOf(id) : -1;
      if (index !== -1) this.QFleftSections[Index].splice(index, 1);
    }
    console.log('Index: ', Index, ' unsaved sections LEFT-SECTION => ', this.QFleftSections);
  }

  /* check if any unsaved left-sections exist within the actvie QUESTION-FOLDER or not */
  public isQFSetLeftSectionUnsaved() {
    console.log(this.QFleftSections);
    if (!(this.QFleftSections.length > 0)) return false;
    for (let section of this.QFleftSections)
      if (section && section.length > 0) return true;
    return false;
  }

  public initQFSetLeftSection() {
    this.QFleftSections = [];
  }

  /**
   * if any changes occured in the LOGIC RIGHT-SECTION then add or remove those section id
   * @param isEdited true ==> UnSAVED false ==> SAVED
   * @param Index index of the operation
   * @param id id of left content
   * @param action possible value 'SECTION EDIT' || 'REMOVE SECTION'
   * @author sumon
   */
  setLogicSetAsEdited(isEdited: boolean, Index, id: string, action: string = 'SECTION EDIT') {
    if (action === 'REMOVE SECTION') {
      this.logicSetEditedSections.splice(Index, 1);
      return;
    }
    if (!this.logicSetEditedSections[Index]) this.logicSetEditedSections[Index] = [];
    if (isEdited) {
      const index = this.logicSetEditedSections[Index] ? this.logicSetEditedSections[Index].indexOf(id) : -1;
      if (index === -1) this.logicSetEditedSections[Index].push(id);
      else this.logicSetEditedSections[Index].splice(index, 1, id);
    } else {
      const index = this.logicSetEditedSections[Index] ? this.logicSetEditedSections[Index].indexOf(id) : -1;
      if (index !== -1) this.logicSetEditedSections[Index].splice(index, 1);
    }
    // console.log('unsaved sections Q-SET => ', this.logicSetEditedSections[Index]);
  }

  /**
   * check if any unsaved logic right-sections exist
   * @returns true if any unsaved content, false if all are saved
   */
  public isLogicSetUnsaved() {
    console.log(this.logicSetEditedSections);
    if (!(this.logicSetEditedSections.length > 0)) return false;
    for (let section of this.logicSetEditedSections)
      if (section && section.length > 0) return true;
    return false;
  }

  /**
   * Function for reset logic Left section content
   */
  public initLogicSet() {
    this.logicSetEditedSections = [];
  }

  /**
   * if any changes occured in the LOGIC LEFT-SECTION (for split screen) then add or remove those section id
   * @param isEdited true ==> UnSAVED false ==> SAVED
   * @param Index index of the operation
   * @param id id of left content
   * @param action possible value 'SECTION EDIT' || 'REMOVE SECTION'
   * @author sumon
   */
  setLogicLeftSectionAsEdited(isEdited: boolean, Index, id: string, action: string = 'SECTION EDIT') {
    // IF ANY LEFT SECTION IS REMOVED THEN REMOVE THE ID OF THAT CONTENT
    if (action === 'REMOVE SECTION') {
      this.LFleftSections.splice(Index, 1);
      return;
    }
    if (!this.LFleftSections[Index]) this.LFleftSections[Index] = [];
    // AT THE TIME OF EDIT OPERATION 
    // isEdited true means content is not updated yet so we have to add content id,
    // false means content is saved then we can remove that content id
    if (isEdited) {
      const index = this.LFleftSections[Index] ? this.LFleftSections[Index].indexOf(id) : -1;
      if (index === -1) this.LFleftSections[Index].push(id);
      else this.LFleftSections[Index].splice(index, 1, id);
    } else {
      const index = this.LFleftSections[Index] ? this.LFleftSections[Index].indexOf(id) : -1;
      if (index !== -1) this.LFleftSections[Index].splice(index, 1);
    }
    // console.log('CommonService @@setLogicLeftSectionAsEdited unsaved sections LEFT-SECTION => ', this.LFleftSections);
  }

  /**
   * check if any unsaved logic left-sections exist
   * @returns true if any unsaved content, false if all are saved
   */
  public isLFSetLeftSectionUnsaved() {
    if (!(this.LFleftSections.length > 0)) return false;
    for (let section of this.LFleftSections)
      if (section && section.length > 0) return true;
    return false;
  }

  /**
   * Function for reset logic Left section content
   */
  public initLFSetLeftSection() {
    // console.log('CommonService @@initLFSetLeftSection initialized for cheking left section validation.');
    this.LFleftSections = [];
  }


  /* if any changes occured in the active PAGE then add or remove those pages */
  setPageAsEdited(isEdited: boolean, Index, id: string, action: string = 'PAGE EDIT') {
    const index = this.pages.indexOf(id);
    if (action === 'REMOVE PAGE') {
      this.pages.splice(index, 1);
      console.log('unsaved sections PAGES => ', this.pages);
      return;
    }
    if (isEdited) {
      if (index === -1) this.pages.push(id);
      else this.pages.splice(index, 1, id);
    } else {
      if (index !== -1) this.pages.splice(index, 1);
    }
    console.log('unsaved sections PAGES => ', this.pages);
  }

  /* check if any unsaved pages exist or not */
  public isTextContentUnsaved() {
    console.log(this.pages);
    if (this.pages.length > 0) return true;
    return false;
  }

  public initTextContent() {
    this.pages = [];
  }

}
