import { Directive, ElementRef, TemplateRef, AfterViewInit, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[mGrammerly]'
})
export class GrammerlyDirective implements AfterViewInit {
  @HostListener('window:keyup', ['$event']) handlekeyup(event: any) {
    this.addProperty();
  }
  @HostListener('window:click', ['$event']) handleclick(event: any) {
    this.addProperty();
  }

  constructor(private elementRef: ElementRef) { }

  addProperty() {
    let editorRef = this.elementRef.nativeElement;
    if (!editorRef) return;
    let div = editorRef.getElementsByTagName('div')[0];
    if (div && Boolean(div.getAttribute('data-enable-grammarly'))) return;
    setTimeout(() => {
      div = editorRef.getElementsByTagName('div')[0];
      // if (div && Boolean(div.getAttribute('data-enable-grammarly'))) return;
      if (!div || Boolean(div.getAttribute('data-enable-grammarly'))) return;
      div.setAttribute('data-enable-grammarly', 'true');
    }, 1000);
  }

  ngAfterViewInit() {
    this.addProperty();
  }

}
