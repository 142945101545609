import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class PublicGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('CurrentUser') == null) {
            // not logged in so return true
            return true;
        }

        // logged in so redirect to subject page 
        this.router.navigate(['/subject']);
        return false;
    }
}