import { NgControl, FormControl } from '@angular/forms';
import { Input, Directive } from '@angular/core';

@Directive({
    selector: '[disableControl]'
})
export class DisableControlDirective {

    @Input() set disableControl(control: FormControl) {
        control.disable();
    }

    constructor() {
    }

}