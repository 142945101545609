import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class NonAuthService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private BASE_URL = environment.base_url;

    constructor(private http: HttpClient) { }

    postUserDetails(data: object) {
        return this.http.post(`${this.BASE_URL}/user/`, data, { headers: this.headers });
    }

    postParentDetails(data: object) {
        return this.http.post(`${this.BASE_URL}/parent/`, data, { headers: this.headers });
    }

    postStudentDetails(data: object) {
        return this.http.post(`${this.BASE_URL}/student/`, data, { headers: this.headers });
    }

    login(data: object) {
        return this.http.post(`${this.BASE_URL}/login/`, data, { headers: this.headers });
    }


}
