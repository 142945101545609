import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../content/pages/dialog-boxes/error-dialog.component';
import { FilterMcqQuestions } from '../content/pages/subject/add-topic-subtopic/subtopic/lecture/lecture-content/quiz/section/explainer/explainer.component';
import { InsertionChoice } from '../content/pages/subject/add-topic-subtopic/subtopic/lecture/lecture-content/test-content/create-test-content/create-test-left-panel/create-test-left-panel.component';
import { ClipboardDirective } from './directives/clipboard.directive';
import { ClipboardModule } from './directives/clipboard.module';
import { CollapseDirective } from './directives/collapse.directive';
import { DisableControlDirective } from './directives/disable-control.directive';
import { HeaderDirective } from './directives/header.directive';
import { MathDirective } from './directives/math.directive';
import { MenuAsideOffcanvasDirective } from './directives/menu-aside-offcanvas.directive';
import { MenuAsideToggleDirective } from './directives/menu-aside-toggle.directive';
import { MenuAsideDirective } from './directives/menu-aside.directive';
import { MenuHorizontalOffcanvasDirective } from './directives/menu-horizontal-offcanvas.directive';
import { MenuHorizontalDirective } from './directives/menu-horizontal.directive';
import { PortletDirective } from './directives/portlet.directive';
import { QuickSearchDirective } from './directives/quick-search.directive';
import { QuickSidebarOffcanvasDirective } from './directives/quick-sidebar-offcanvas.directive';
import { ResizableDirective } from './directives/resizable.directive';
import { ResizeBarDirective } from './directives/resize-bar.directive';
import { ScrollTopDirective } from './directives/scroll-top.directive';
import { uniqueValidatorEmailDirective } from './directives/uniqueemail.directive';
import { uniqueValidatorUsernameDirective } from './directives/uniqueusername.directive';
import { ConsoleLogPipe } from './pipes/console-log.pipe';
import { FirstLetterPipe } from './pipes/first-letter.pipe';
import { FirstLinePipe } from './pipes/first-line.pipe';
import { GetLectureId } from './pipes/get-lecture-id.pipe';
import { GetObjectPipe } from './pipes/get-object.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SelectedQuesTestModule } from './pipes/selected-ques-test-module.pipe';
import { TimeElapsedPipe } from './pipes/time-elapsed.pipe';
import { VisibilityCheck } from './pipes/visibility-check.pipe';
import { AuthService } from './services/APIService/auth.service';
import { NonAuthService } from './services/APIService/nonauth.service';
import { ValidationService } from './services/APIService/validation.service';
import { CommonDialog } from './shared/common-dialog.component';
import { UiBlockLoaderModule } from './shared/ui-block-loader.component';
import { GrammerlyModule } from './directives/grammerly.module';
import { FileUploadingService } from './services/file-uploading.service';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		MatDialogModule,
		MatNativeDateModule,
		UiBlockLoaderModule,
		ClipboardModule,
		GrammerlyModule,
	],
	declarations: [

		// components
		ErrorDialogComponent,
		CommonDialog,
		InsertionChoice,


		// directives
		MenuAsideDirective,
		MenuAsideOffcanvasDirective,
		MenuHorizontalOffcanvasDirective,
		MenuHorizontalDirective,
		ScrollTopDirective,
		HeaderDirective,
		MenuAsideToggleDirective,
		QuickSidebarOffcanvasDirective,
		QuickSearchDirective,
		// ClipboardDirective,
		PortletDirective,
		uniqueValidatorUsernameDirective,
		uniqueValidatorEmailDirective,
		ResizableDirective,
		DisableControlDirective,
		CollapseDirective,
		ResizeBarDirective,
		MathDirective,

		// pipes
		FirstLetterPipe,
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		ConsoleLogPipe,
		SafePipe,
		FilterMcqQuestions,
		SafeHtmlPipe,
		GetLectureId,
		VisibilityCheck,
		FirstLinePipe,
		SelectedQuesTestModule
	],
	exports: [
		// directives
		MenuAsideDirective,
		MenuAsideOffcanvasDirective,
		MenuHorizontalOffcanvasDirective,
		MenuHorizontalDirective,
		ScrollTopDirective,
		HeaderDirective,
		MenuAsideToggleDirective,
		QuickSidebarOffcanvasDirective,
		QuickSearchDirective,
		ClipboardDirective,
		PortletDirective,
		uniqueValidatorUsernameDirective,
		uniqueValidatorEmailDirective,
		ResizableDirective,
		DisableControlDirective,
		CollapseDirective,
		ResizeBarDirective,
		MathDirective,
		
		// pipes
		FirstLetterPipe,
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		ConsoleLogPipe,
		SafePipe,
		FilterMcqQuestions,
		SafeHtmlPipe,
		GetLectureId,
		VisibilityCheck,
		FirstLinePipe,
		SelectedQuesTestModule
	],
	providers: [ValidationService, NonAuthService, AuthService, FileUploadingService],
	entryComponents: [
		ErrorDialogComponent,
		CommonDialog,
		InsertionChoice
	],
})
export class CoreModule { }
