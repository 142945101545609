import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest } from '@angular/common/http';

import { environment } from './../../../../environments/environment';
import { RequestOptions } from '@angular/http';

@Injectable({
    providedIn: 'root'
})

export class AuthService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private headers2 = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
    private headers1 = new HttpHeaders({ Accept: 'application/vnd.api.video+json;version=1' }).append('Authorization', 'bearer {access_token}');

    private BASE_URL = environment.base_url;
    private FAKE_URL = 'http://localhost:3000';

    constructor(private http: HttpClient) { }



    /* Subject section */
    addSubject(data: object) {
        return this.http.post(`${this.BASE_URL}/add/subject/`, data, { headers: this.headers });
    }

    editSubject(data: object, subject_id: any) {
        return this.http.put(`${this.BASE_URL}/subject/edit/${subject_id}`, data, { headers: this.headers });
    }

    getSubjectList(userid: object, params: any) {
        return this.http.post(`${this.BASE_URL}/list/subject/`, userid, { params: params, headers: this.headers });
    }

    getMasterSubjectsList() {
        return this.http.get(`${this.BASE_URL}/list/master/subject/`, { headers: this.headers })
    }
    // Skill section
    addSkillbook(data: object) {
        return this.http.post(`${this.BASE_URL}/add_skillbook/`, data, { headers: this.headers });
    }
    editSkillbook(data: object) {
        return this.http.put(`${this.BASE_URL}/add_skillbook/`, data, { headers: this.headers });
    }
    deleteSkillbook(data: object) {
        let obj={
            headers: this.headers,
            body:data
        }
        return this.http.delete(`${this.BASE_URL}/add_skillbook/`, obj );
    }
       /* GET skill/main-topic/sub-topic */
     getSkillbookById(id) {
        return this.http.get(`${this.BASE_URL}/list/skill-book/`+id+'/', {headers: this.headers });
    }
     /* add skill Topic Section */
     addSkillTopic(data: object) {
        return this.http.post(`${this.BASE_URL}/add_skilltopic/`, data, { headers: this.headers });
    }
    editSkillTopic(data: object) {
        return this.http.put(`${this.BASE_URL}/add_skilltopic/`, data, { headers: this.headers });
    }
    deleteSkillTopic(data: object) {
        let obj={
            headers: this.headers,
            body:data
        }
        return this.http.delete(`${this.BASE_URL}/add_skilltopic/`, obj);
    }
    getSkillTopic(id) {
        return this.http.get(`${this.BASE_URL}/list/skill-topic/`+id+'/', { headers: this.headers });
    }
       /* add skill sub-Toppic Section */
       addSkillSubTopic(data: object) {
        return this.http.post(`${this.BASE_URL}/add_skill/`, data, { headers: this.headers });
    }
    editSkillSubTopic(data: object) {
        return this.http.put(`${this.BASE_URL}/add_skill/`, data, { headers: this.headers });
    }
    deleteSkillSubTopic(data: object) {
        let obj={
            headers: this.headers,
            body:data
        }
        return this.http.delete(`${this.BASE_URL}/add_skill/`, obj);
    }



    /* Main-Toppic Section */
    addTopic(data: object, queryParams: any) {
        return this.http.post(`${this.BASE_URL}/add/topic/`, data, { params: queryParams, headers: this.headers });
    }

    editTopic(id: number, data: object, queryParams: any) {
        return this.http.put(`${this.BASE_URL}/topic/edit/${id}`, data, { params: queryParams, headers: this.headers });
    }

    removeTopic(id: number, subject_id: number, queryParams: any) {
        return this.http.delete(`${this.BASE_URL}/topic/edit/${id}/${subject_id}`, { params: queryParams });
    }


    /* Sub-Topic Section */
    addSubTopic(data: object, queryParams: any) {
        return this.http.post(`${this.BASE_URL}/add/subtopic/`, data, { params: queryParams, headers: this.headers });
    }

    editSubTopic(id: number, data: object, queryParams: any) {
        return this.http.put(`${this.BASE_URL}/subtopic/edit/${id}`, data, { params: queryParams, headers: this.headers });
    }

    removeSubTopic(sub_topic_id: number, main_topic_id: number, queryParams: any) {
        return this.http.delete(`${this.BASE_URL}/subtopic/delete/${sub_topic_id}/${main_topic_id}`, { params: queryParams });
    }


    /* Lecture section */
    addLecture(data: object, queryParams: any) {
        return this.http.post(`${this.BASE_URL}/add/lecture/`, data, { params: queryParams, headers: this.headers });
    }

    editLecture(id: number, data: object, queryParams: any) {
        return this.http.put(`${this.BASE_URL}/edit/lecture/${id}/`, data, { params: queryParams, headers: this.headers });
    }

    removeLecture(lecture_id: number, sub_topic_id: number) {
        const data: object = {
            lecture_id: lecture_id,
            subtopic_id: sub_topic_id
        }
        // return this.http.post(`${this.BASE_URL}/lecture/deactivate/`, data, { headers: this.headers });
        return this.http.delete(`${this.BASE_URL}/lecture/deactivate/?lecture_id=${lecture_id}&subtopic_id=${sub_topic_id}`, { headers: this.headers });
    }



    /* Lecture content section */

    getLectureContent(data: any) {
        // return this.http.post(`${this.BASE_URL}/lect/data/`, data, { headers: this.headers });
        return this.http.get(`${this.BASE_URL}/lect/data/?lect_id=${data.lect_id}`, { headers: this.headers });
    }

    changeLectureContentOrder(OBJ: object, lecture_id?: any) {
        // return this.http.patch(`${this.BASE_URL}/edit/lecture/${lecture_id}/`, OBJ, { headers: this.headers });
        return this.http.post(`${this.BASE_URL}/change-component-order/`, OBJ, { headers: this.headers });
    }





    /* GET subject/main-topic/sub-topic */
    getRecord(obj: any) {
        // return this.http.post(`${this.BASE_URL}/list/record/`, $OBJ, { headers: this.headers });
        return this.http.get(`${this.BASE_URL}/list/record/`, { params: obj, headers: this.headers });
    }

    /* add content section */
    UploadVideo(data: object) {
        return this.http.post(`https://api.vimeo.com/me/videos`, data, {
            reportProgress: true,
            observe: 'events'
        });
    }

    /* Drag-and-Drop section */
    DragDrop_MainTopic(id: number, data: object, queryParams: any) {
        return this.http.put(`${this.BASE_URL}/subject/edit/${id}`, data, { params: queryParams, headers: this.headers });
    }

    DragDrop_SubTopic(id: number, data: object, queryParams: any) {
        return this.http.put(`${this.BASE_URL}/topic/edit/${id}`, data, { params: queryParams, headers: this.headers });
    }

    DragDrop_Lecture(id: number, data: object, queryParams: any) {
        return this.http.put(`${this.BASE_URL}/subtopic/edit/${id}`, data, { params: queryParams, headers: this.headers });
    }

    DragDrop_Question_Folder(question_id: number, position: number, quizbank_id: string) {
        const data = {
            question_id: question_id,
            ques_position: position,
            quizbank_id: parseInt(quizbank_id)
        }
        return this.http.post(`${this.BASE_URL}/reorder/question/folder/`, data, { headers: this.headers });
    }




    /* QUIZ-BANK section */

    addQuizBank(data: object) {
        return this.http.post(`${this.BASE_URL}/add/qbank/`, data, { headers: this.headers });
    }

    editQuizBank(id: number, data: object) {
        return this.http.patch(`${this.BASE_URL}/edit/qbank/${id}/`, data, { headers: this.headers });
    }

    removeQuizBank(id: number, lecture_id: number) {
        const data: object = {
            quizbank_id: id,
            lecture_id: lecture_id
        }
        return this.http.delete(`${this.BASE_URL}/qbank/deactivate/?lecture_id=${lecture_id}&quizbank_id=${id}`, { headers: this.headers });
    }


    /* QUESTION-FOLDER section */

    getAllSchool() {
        return this.http.get(`${this.BASE_URL}/register/list-school/`, { headers: this.headers });
    }

    addQuestionFolder(question_folder_JSON: object) {
        return this.http.post(`${this.BASE_URL}/add/question/folder/data/`, question_folder_JSON, { headers: this.headers });
    }

    editQuestionFolder(id: number, question_folder_JSON: object) {
        return this.http.patch(`${this.BASE_URL}/edit/question/folder/data/${id}/`, question_folder_JSON, { headers: this.headers });
    }

    removeQuestionFolder(id: number, quiz_bank_id: number) {
        let data: object = {
            quizbank_id: quiz_bank_id,
            question_id: id
        }
        // return this.http.post(`${this.BASE_URL}/question/folder/deactivate/`, data, { headers: this.headers });
        return this.http.delete(`${this.BASE_URL}/question/folder/deactivate/?quizbank_id=${quiz_bank_id}&question_id=${id}`, { headers: this.headers });
    }

    copyQuestionFolder(teacher_id: number, question_folder_id: number, quiz_bank_id: string) {
        const OBJ = {
            teacher_id: teacher_id,
            question_id: question_folder_id,
            quizbank_id: parseInt(quiz_bank_id)
        }
        return this.http.post(`${this.BASE_URL}/copy/question/folder/`, OBJ, { headers: this.headers });
    }

    customiseQuestionFolder(question_folder_id: number, customiseObj: any) {
        return this.http.patch(`${this.BASE_URL}/add/custom/question/folder/${question_folder_id}/`, customiseObj, { headers: this.headers });
    }

    removeChildQuestionFolder(parent_question_folder_id: number, child_question_folder_id: number) {
        return this.http.get(`${this.BASE_URL}/child/question/folder/${parent_question_folder_id}/deactivate/`);
    }

    duplicateQuestionFolder(teacher_id: number, question_folder_id: number, quiz_bank_id: string) {
        const OBJ = {
            teacher_id: teacher_id,
            question_id: question_folder_id,
            quizbank_id: parseInt(quiz_bank_id)
        }
        return this.http.post(`${this.BASE_URL}/clone/question/folder/`, OBJ, { headers: this.headers });
    }

    getParentChildQuestionFolder(teacher_id: number, question_folder_id: number, pageNumber) {
        const OBJ = {
            teacher_id: teacher_id,
            question_id: question_folder_id
        }
        return this.http.post(`${this.BASE_URL}/list/question/folder/child/?page=${pageNumber}`, OBJ, { headers: this.headers });
    }

    getAllQuestionFolders(filterObj: any) {
        // let OBJ = {
        //     quiz_bank_id: parseInt(quiz_bank_id),
        //     search_flag: Object.entries(filterObj).length === 0 ? 0 : 1,
        //     importance: filterObj.importance,
        //     complexity: filterObj.complexity,
        //     question_mark: filterObj.marks,
        //     main_question_type: filterObj.main_question_type,
        //     screen_type: filterObj.screen_type,
        //     compulsory: filterObj.compulsory,

        // };

        // for (let [key, value] of Object.entries(OBJ)) {
        //     if (value === "none" || value === undefined)
        //         // delete OBJ[key];
        //         OBJ[key] = "";
        // }

        // return this.http.post(`${this.BASE_URL}/list/question/folder/search/?page=${pageNumber}`, OBJ, { headers: this.headers });
        return this.http.get(`${this.BASE_URL}/quiz-bank/list-question-folder/`, { params: filterObj });
    }

    getAllSections(data: object) {
        return this.http.post(`${this.BASE_URL}/list/question/data`, data, { headers: this.headers });
    }

    storeSkills(question_folder_id: number, skills: object) {
        let OBJ = {
            question_id: question_folder_id,
            skill_set: skills
        };
        return this.http.post(`${this.BASE_URL}/store/skills/`, OBJ, { headers: this.headers });
    }

    /* LOGIC-LESSON section */

    addLogicLesson(data: object) {
        return this.http.post(`${this.BASE_URL}/add/logic/`, data, { headers: this.headers });
    }

    editLogicLesson(id: number, data: object) {
        return this.http.patch(`${this.BASE_URL}/edit/logic/${id}/`, data, { headers: this.headers });
    }

    removeLogicLesson(id: number, lecture_id: number) {
        const data: object = {
            lecture_id: lecture_id,
            lesson_id: id
        }
        return this.http.delete(`${this.BASE_URL}/logic/deactivate/?lecture_id=${lecture_id}&lesson_id=${id}`, { headers: this.headers });
    }

    editLogicalLessonData(id: number, Logical_Lesson_JSON: object) {
        return this.http.patch(`${this.BASE_URL}/edit/logic/${id}/`, Logical_Lesson_JSON, { headers: this.headers });
    }

    getLogicLessonContent(id: number) {
        return this.http.get(`${this.BASE_URL}/lesson/${id}`);
    }

    /* TEXT-CONTENT section */
    addTextContent(data: object) {
        return this.http.post(`${this.BASE_URL}/add/content/`, data, { headers: this.headers });
    }

    editTextContent(id: number, data: object) {
        return this.http.patch(`${this.BASE_URL}/edit/content/${id}/`, data, { headers: this.headers });
    }

    removeTextContent(id: number, lecture_id: number) {
        const data: object = {
            lecture_id: lecture_id,
            content_id: id
        }
        return this.http.delete(`${this.BASE_URL}/content/deactivate/?lecture_id=${lecture_id}&content_id=${id}`, { headers: this.headers });
    }

    getPagesOfTextContent(id: number) {
        return this.http.get(`${this.BASE_URL}/content/${id}/`);
    }

    // TEST-CONTENT SECTION

    addTestContent(data: object) {
        return this.http.post(`${this.BASE_URL}/add/test/`, data, { headers: this.headers });
    }

    editTestContent(id: number, data: object) {
        return this.http.patch(`${this.BASE_URL}/edit/test/${id}/`, data, { headers: this.headers });
    }

    removeTestContent(id: number, lecture_id: number) {
        const data: object = {
            lecture_id: lecture_id,
            test_id: id
        }
        return this.http.delete(`${this.BASE_URL}/test/deactivate/?lecture_id=${lecture_id}&test_id=${id}`, { headers: this.headers });
    }

    listMockExams() {
        return this.http.get(`${this.BASE_URL}/list-mock-exams/`, { headers: this.headers });
    }

    listAssessments() {
        return this.http.get(`${this.BASE_URL}/assessment-list/`, { headers: this.headers });
    }


    /* VIDEO section */
    addVideoContent(data: object) {
        return this.http.post(`${this.BASE_URL}/add/video/`, data, { headers: this.headers });
    }

    editVideoContent(id: number, data: object) {
        return this.http.patch(`${this.BASE_URL}/edit/video/${id}/`, data, { headers: this.headers });
    }

    removeVideo(id: number, lecture_id: number) {
        const data: object = {
            lecture_id: lecture_id,
            video_id: id
        }
        return this.http.delete(`${this.BASE_URL}/video/deactivate/?lecture_id=${lecture_id}&video_id=${id}`, { headers: this.headers });
    }

    /* TeachingMaterial section */
    addTeachingMaterialContent(data: object) {
        return this.http.post(`${this.BASE_URL}/add/video/`, data, { headers: this.headers });
    }

    editTeachingMaterialContent(id: number, data: object) {
        return this.http.patch(`${this.BASE_URL}/edit/video/${id}/`, data, { headers: this.headers });
    }

    removeTeachingMaterial(id: number, lecture_id: number) {
        return this.http.delete(`${this.BASE_URL}/video/deactivate/?lecture_id=${lecture_id}&teaching_material_id=${id}`, { headers: this.headers });
    }

    // Test question module
    getQuestionModal(subjectId) {
        let data = { "subject_id": subjectId };
        return this.http.post(`${this.BASE_URL}/list/subject/sort/`, data, { headers: this.headers });
    }


    /* Skills related */
    getSkillsList() {
        return this.http.get(`${this.BASE_URL}/list/skills/`);
    }


    /* Test Model related */
    updateTestModel(testQuesId, data) {
        return this.http.patch(`${this.BASE_URL}/edit/test/${testQuesId}/`, data, { headers: this.headers });
    }

    fetchTestModel(testQuesId) {
        return this.http.get(`${this.BASE_URL}/test/${testQuesId}/`, { headers: this.headers });
    }

    totaSelectedQuestion(quiz_bank_ids, params) {
        const fd = new FormData();
        fd.append("filter_data", JSON.stringify(quiz_bank_ids));
        return this.http.post(`${this.BASE_URL}/filter/data/`, fd, {
            params: params
        });
    }

    getAllQuestionFoldersList(params) {
        // return this.http.get(`${this.BASE_URL}/list/all/question/folder/?page=${pageNumber}`, { headers: this.headers });
        return this.http.get(`${this.BASE_URL}/list-question-folder/`, {
            params: params
        });
    }

    getQFfilterData() {
        // return this.http.get(`${this.BASE_URL}/list/all/question/folder/?page=${pageNumber}`, { headers: this.headers });
        return this.http.get(`${this.BASE_URL}/list-filter-data/`, { headers: this.headers });
    }



    /* copy components api section */
    copyComponent(data) {
        return this.http.post(`${this.BASE_URL}/copy/component/`, data, { headers: this.headers });
    }

    copyLPComponent(lpId: number) {
        return this.http.get(`${this.BASE_URL}/copy_lp_course?id=${lpId}`);
    }


    /* issues or report section */
    getAllIssues(data, pageNumber, params) {

        // return this.http.post(`${this.BASE_URL}/list-registered-issues/?page=${pageNumber}`, data, { headers: this.headers });
        return this.http.get(`${this.BASE_URL}/list-student-issue/`, {
            params: params
        });
    }

    getIssuesFilterData() {
        return this.http.get(`${this.BASE_URL}/list-issue-filter-data/`, { headers: this.headers });
    }

    updateIssue(data) {
        return this.http.patch(`${this.BASE_URL}/update-student-issues/`, data, { headers: this.headers });
    }


    /* ask-doubt section */
    getAlldoubts(params) {
        return this.http.get(`${this.BASE_URL}/list-student-doubt/`, {
            params: params
        });
    }

    getDoubtStudentDetails(data) {
        return this.http.post(`${this.BASE_URL}/list-student-insight/`, data, { headers: this.headers });
    }

    updateDoubtDetails(data, doubt_id) {
        return this.http.patch(`${this.BASE_URL}/update-student-doubt/${doubt_id}`, data, { headers: this.headers });
    }



    /* message box section */

    /* get list of chats ask by a specific student againest a specific doubt */
    getChatHistory(OBJ) {
        return this.http.post(`${this.BASE_URL}/list-message/`, OBJ, { headers: this.headers });
    }

    /* send a message by a specific student againest a specific doubt */
    sendMessage(OBJ) {
        return this.http.post(`${this.BASE_URL}/add-new-message/`, OBJ, { headers: this.headers });
    }

    /* update a message by a specific student againest a specific doubt */
    updateMessage(OBJ, msg_id) {
        return this.http.patch(`${this.BASE_URL}/update-message/${msg_id}`, OBJ, { headers: this.headers });
    }

    /* check for new or unread messages count for a specific doubt */
    getUnreadMessageCount(OBJ) {
        return this.http.post(`${this.BASE_URL}/get-unread-message/`, OBJ, { headers: this.headers });
    }



    /* feature-support section */
    getAlltickets(params) {
        return this.http.get(`${this.BASE_URL}/list-customer-ticket/`, {
            params: params
        });
    }

    /* get list of conversations ask by a specific student against a specific ticket */
    getConversationsHistory(OBJ) {
        return this.http.post(`${this.BASE_URL}/list-of-communication/`, OBJ, { headers: this.headers });
    }

    /* send a new conversation by a specific student against a specific ticket */
    sendNewConversation(OBJ) {
        return this.http.post(`${this.BASE_URL}/add-new-communication/`, OBJ, { headers: this.headers });
    }

    /* update an existing conversation by a specific student against a specific ticket */
    updateConversation(OBJ, conversation_id) {
        return this.http.patch(`${this.BASE_URL}/update-communication/${conversation_id}`, OBJ, { headers: this.headers });
    }

    /* check for new or unread conversations count for a specific ticket */
    getUnreadConvesationCount(OBJ) {
        return this.http.post(`${this.BASE_URL}/get-unread-conversation/`, OBJ, { headers: this.headers });
    }

    updateTicketDetails(data, ticket_id) {
        return this.http.patch(`${this.BASE_URL}/update-student-ticket/${ticket_id}`, data, { headers: this.headers });
    }


    /* check for new or unread conversations count for a all tickets */
    getAllTicketsUnreadConversationCount(OBJ) {
        return this.http.post(`${this.BASE_URL}/get-all-unread-conversation/`, OBJ, { headers: this.headers });
    }

    /* get account insight and ticket details */
    getInsightDetails(OBJ) {
        return this.http.post(`${this.BASE_URL}/list-account-insight/`, OBJ, { headers: this.headers });
    }

    /* get all unread messages and conversations count for all tickets and doubts */
    getAllUnreadCount() {
        return this.http.get(`${this.BASE_URL}/get-all-unread-msg-conv/`, { headers: this.headers });
    }


    /* This API will list out all the skill categories which are available in db */
    getSkillCategories() {
        return this.http.get(`${this.BASE_URL}/list/skill-categories/`, { headers: this.headers });
    }

    /* This API will filter out the skill books by skill category */
    getSkillBooks(skill_category_id) {
        return this.http.get(`${this.BASE_URL}/list/skill-books/${skill_category_id}`, { headers: this.headers });
    }
    /* This API will filter out the skill books by skill category */
    getSkillBooksas(skill_category_id, params: any) {
        return this.http.get(`${this.BASE_URL}/list/get_skill_books_sb/${skill_category_id}`, { params, headers: this.headers });
    }

    /* This API will filter out the skill topics by skill book */
    getSkillTopics(skill_book_id) {
        return this.http.get(`${this.BASE_URL}/list/skill-topics/${skill_book_id}`, { headers: this.headers });
    }

    /* This API will filter out skill data by skill topic */
    getSkill(skill_topic_id) {
        return this.http.get(`${this.BASE_URL}/list/skill/${skill_topic_id}`, { headers: this.headers });
    }

    /* It will add new skill Info for the QF */
    addQFSkill(OBJ) {
        return this.http.post(`${this.BASE_URL}/add/section-skill/`, OBJ, { headers: this.headers });
    }

    /* This API used to retrieve the section-skill data of QF */
    listOfAddedSkills(OBJ) {
        return this.http.post(`${this.BASE_URL}/list/get-section-skills/`, OBJ, { headers: this.headers });
    }

    /* it is used to deactivate skill row in db */
    removeSectionSkill(id) {
        return this.http.get(`${this.BASE_URL}/remove-section-skill/${id}`, { headers: this.headers });
    }

    /* It does reorder of skill which will displayed on each sections.  */
    reorderSkills(OBJ) {
        return this.http.post(`${this.BASE_URL}/reorder-section-skill/`, OBJ, { headers: this.headers });
    }

    getGrades() {
        return this.http.get(`${this.BASE_URL}/list_grades/`);
    }

    getNewSubjectList(params: any) {
        return this.http.get(`${this.BASE_URL}/list-superbooks/`, { params: params });
    }
    getSkillBookList(params: any) {
        return this.http.get(`${this.BASE_URL}/list/skill-book/`, { params: params });
    }
    getAllSkillCategory() {
        return this.http.get(`${this.BASE_URL}/list/skill-category/`);
    }

    getCoursesList(object: any) {
        return this.http.get(`${this.BASE_URL}/list-courses/`, { params: object});
    }

    getCourseSuperbookList(object: any) {
        return this.http.get(`${this.BASE_URL}/get_course/`, { params: object });
    }
    // edit course book
    editCourseBook(data: object, course_id: any,userid) {
        return this.http.put(`${this.BASE_URL}/lp/edit/${course_id}?analytics_mode=false&userid=${userid}`, data, { headers: this.headers });
    }
     /* course section */
     addCourseBook(data: object,userid) {
        return this.http.post(`${this.BASE_URL}/add_learning_path/?analytics_mode=false&userid=${userid}`, data);
    }

    addCoursePath(data: object,userid) {
        return this.http.post(`${this.BASE_URL}/add_course_path?analytics_mode=false&userid=${userid}`, data);
    }
    /* Drag-and-Drop section */
    DragDrop_booksInCoursebook(courseId: string, data: object) {
        return this.http.put(`${this.BASE_URL}/get_course/`, data, { params: {courseid: courseId}, headers: this.headers });
    }

    copyBookinCourse(courseid: string, superbookId: string) {
        return this.http.get(`${this.BASE_URL}/copy_superbook_to_course/${superbookId}/${courseid}`);
    }
    
    removeBookfromCourse(courseid: string, superbookId: string) {
        return this.http.get(`${this.BASE_URL}/remove_superbook_to_course/${superbookId}/${courseid}`);
    }

    multipleDuplicateQuestionFolder(OBJ: any) {
        return this.http.post(`${this.BASE_URL}/clone/question/folders/`, OBJ, { headers: this.headers });
    }

    duplicateQuizbank(data) {
        return this.http.post(`${this.BASE_URL}/clone/quizbank/`, data, { headers: this.headers });
    }

    /* This API will filter out the skill topics by skill book */
    getSkillTopicsWithAssociateSkills(skill_book_id) {
        return this.http.get(`${this.BASE_URL}/list/skill-topics-tree/${skill_book_id}`, { headers: this.headers });
    }

    getSkillDataByTopic(data) {
        return this.http.post(`${this.BASE_URL}/list/get_section_skills_list_data/`, data, { headers: this.headers });
    }

    saveSkillData(data) {
        return this.http.post(`${this.BASE_URL}/add_skill_content/`, data, { headers: this.headers });
    }

    saveSkillDataForQuestionSet(data) {
        return this.http.post(`${this.BASE_URL}/add_qf_qs_skills`, data, { headers: this.headers });
    }
    
    addSkillToMultipleQF(data) {
        return this.http.post(`${this.BASE_URL}/add/skill-to-qf/`, data, { headers: this.headers });
    }

    addSkillToLectureComponent(data) {
        return this.http.post(`${this.BASE_URL}/add/skill-to-lec-component/`, data, { headers: this.headers });
    }

    updateChildSkillsFromLectureLevel(data) {
        return this.http.post(`${this.BASE_URL}/skill_operations_lec`, data);
    }

    updateChildSkillsFromQBLevel(data) {
        return this.http.post(`${this.BASE_URL}/skill_operations_qb`, data);
    }

    updateChildSkillsFromQFLevel(data) {
        return this.http.post(`${this.BASE_URL}/skill_operations_qf`, data);
    }

    getAllParentInfoForAContent(data: any) {
        return this.http.post(`${this.BASE_URL}/get_parent`, data);
    }

}
