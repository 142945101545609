import { Component, OnInit, OnDestroy, ElementRef, ViewChild, EventEmitter, Output, ChangeDetectorRef, Input } from '@angular/core';
import { CommonService } from '../../../../../../../../../../core/services/APIService/common.service';
import { AuthService } from '../../../../../../../../../../core/services/APIService/auth.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { CommonDialog } from '../../../../../../../../../../core/shared/common-dialog.component';
import { DataStoreService } from './data-store.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'insertion-choice',
  template: `
  <div class="commonModalBody">
    <div class="radio-list">
      <div class="list">
        <input type="radio" name="list" (change)="getValue('before')" />
        <div class="txt">
          Insert Before<br/><small>This will insert a new one before the current selection.</small>
        </div>
      </div>
      <div class="list">
        <input type="radio" name="list" (change)="getValue('after')"/>
        <div class="txt">
          Insert After<br/><small>This will insert a new one after the current selection.</small>
        </div>
      </div>
    </div>
  </div>
  `
})
export class InsertionChoice {
  title: string = 'Insertion';

  constructor(public dataStoreService: DataStoreService) { }

  getValue(value) {
    this.dataStoreService.setInsertionAction(value);
  }
}

@Component({
  selector: 'm-create-test-left-panel',
  templateUrl: './create-test-left-panel.component.html',
  styleUrls: ['./create-test-left-panel.component.scss']
})
export class CreateTestLeftPanelComponent extends InsertionChoice implements OnInit, OnDestroy {
  subscription$: Subscription[] = [];
  @ViewChild('rightsidemenubar') rightSideMenu: ElementRef;

  @Input('groups') groups: any[] = [];
  @Input('activeGroupIndex') activeGroupIndex;  /* identify which group from the list of groups is currenly selected or active */
  @Input('activeSectionIndex') activeSectionIndex;  /* identify which section from the list of sections within a particuer group is currenly selected or active */

  @Output('groupDataChild') groupDataChild = new EventEmitter<any>();
  @Input() counter;
  sections: any[] = [];
  subject_id: any;
  test_content_id: any;
  loading: boolean = false;
  constructor(
    public dataStoreService: DataStoreService,
    private snackBar: MatSnackBar,
    private commonService: CommonService,
    private authService: AuthService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef) {
    super(dataStoreService);
    this.subject_id = this.route.snapshot.queryParams['subject_id'];
    this.test_content_id = this.route.snapshot.queryParams['test_content_id'];
  }

  /* Open a modal dialog and pass the sections[] array */
  /*openDialogForDradDrop(): void {
    const DIALOGREF = this.dialog.open(DragDropDialogComponent, {
      height: 'auto',
      width: '660px',
      panelClass: 'modal-class',
      data: this.sections,
    });

    DIALOGREF.afterClosed().subscribe((sections: any[]) => {
      console.log('The dialog was closed', sections);
      if (sections) {
        this.sections = sections;
        const SECTIONS_OBJ = {
          // time: null,
          sections: this.sections
        }
        this.sectionDataChild.emit(SECTIONS_OBJ);
      }
    });
  }*/

  toggleMenu() {
    if (this.rightSideMenu.nativeElement.classList.contains('open')) {
      this.rightSideMenu.nativeElement.classList.remove('open');
    } else {
      this.rightSideMenu.nativeElement.classList.add('open');
    }
  }

  openInsertionDialog(title?: string) {
    return this.dialog.open(CommonDialog, {
      width: '500px',
      data: {
        component: InsertionChoice,
        title: title ? title : this.title
      }
    });
  }


  /* Mainly use to add each group dynamically */
  addGroup() {
    this.loading = true;
    console.log('activeGroupIndex', this.activeGroupIndex);
    this.sections = [];
    let GROUP_OBJ = {
      id: this.commonService.generateUniqueId(),
      type: 'GROUP',
      name: `group name`,
      sections: this.sections,
    }
    if (this.groups.length === 0) {
      const ARRAY = this.groups.splice(0, this.activeGroupIndex + 1);
      this.dataStoreService.resetInsertionAction();
      this.groups = [...ARRAY, GROUP_OBJ, ...this.groups];
      this.groupDataChild.emit(this.groups);
      this.loading = false;
    } else {
      this.subscription$.push(
        this.openInsertionDialog('Insert Group').afterClosed().subscribe(action => {
          console.log(action);
          if (action !== 'ok') { this.loading = false; this.cd.markForCheck(); return; }
          let ARRAY = this.dataStoreService.getInsertionAction() === 'after' ? this.groups.splice(0, this.activeGroupIndex + 1) : this.groups.splice(0, this.activeGroupIndex);
          this.groups = [...ARRAY, GROUP_OBJ, ...this.groups];
          this.dataStoreService.resetInsertionAction();
          this.groupDataChild.emit(this.groups);
          this.loading = false;
        })
      );
    }
  }

  /* for adding add text-editor dynamically */
  addText() {
    if (this.loading || this.groups.length === 0 || !(this.activeGroupIndex < this.groups.length)) return;

    this.loading = true;
    console.log('activeGroupIndex', this.activeGroupIndex);
    this.getAllsections(this.activeGroupIndex);
    let activeSection = this.groups[this.activeGroupIndex];

    let TEXT_OBJ = {
      id: this.commonService.generateUniqueId(),
      type: 'TEXT',
      data: null,
    }
    if (this.sections.length === 0) {
      const ARRAY = this.sections.splice(0, this.activeSectionIndex + 1);
      this.dataStoreService.resetInsertionAction();
      this.sections = [...ARRAY, TEXT_OBJ, ...this.sections];
      let GROUP_OBJ = {
        id: this.commonService.generateUniqueId(),
        type: activeSection.type,
        name: activeSection.name,
        sections: this.sections,
      }

      this.groups.splice(this.activeGroupIndex, 1, GROUP_OBJ);
      this.groupDataChild.emit(this.groups);
      this.loading = false;
    } else {
      this.subscription$.push(
        this.openInsertionDialog('Insert Text').afterClosed().subscribe(action => {
          if (action !== 'ok') { this.loading = false; this.cd.markForCheck(); return; }
          let ARRAY = this.dataStoreService.getInsertionAction() === 'after' ? this.sections.splice(0, this.activeSectionIndex + 1) : this.sections.splice(0, this.activeSectionIndex);
          this.dataStoreService.resetInsertionAction();
          this.sections = [...ARRAY, TEXT_OBJ, ...this.sections];
          let GROUP_OBJ = {
            id: this.commonService.generateUniqueId(),
            type: activeSection.type,
            name: activeSection.name,
            sections: this.sections,
          }

          this.groups.splice(this.activeGroupIndex, 1, GROUP_OBJ);
          this.groupDataChild.emit(this.groups);
          this.loading = false;
        })
      );
    }



  }


  /* for adding add addQuestionFilter dynamically */
  addQuestionFilter() {
    if (this.loading || this.groups.length === 0 || !(this.activeGroupIndex < this.groups.length)) return;

    this.loading = true;
    this.authService.getQuestionModal(this.subject_id)
      .subscribe((treedata: any) => {
        const TREE_DATA: any[] = treedata.maintopic_info ? treedata.maintopic_info : [];

        console.log('activeGroupIndex', this.activeGroupIndex);
        this.getAllsections(this.activeGroupIndex);
        let activeSection = this.groups[this.activeGroupIndex];


        let FILTER_OBJ = {
          id: this.commonService.generateUniqueId(),
          type: 'FILTER',
          isEditEnable: false,
          data: {
            treeData: TREE_DATA
          },
        }

        if (this.sections.length === 0) {
          const ARRAY = this.sections.splice(0, this.activeSectionIndex + 1);
          this.dataStoreService.resetInsertionAction();
          this.sections = [...ARRAY, FILTER_OBJ, ...this.sections];
          if (TREE_DATA.length <= 0) {
            this.snackBar.open('No data found for filtration', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: 'end', panelClass: ['warning-snackbar'] });
          } else {

            let GROUP_OBJ = {
              id: this.commonService.generateUniqueId(),
              type: activeSection.type,
              name: activeSection.name,
              sections: this.sections,
            }

            this.groups.splice(this.activeGroupIndex, 1, GROUP_OBJ);
            this.groupDataChild.emit(this.groups);
            this.loading = false;

          }

        } else {
          this.subscription$.push(
            this.openInsertionDialog('Insert Filter').afterClosed().subscribe(action => {
              if (action !== 'ok') { this.loading = false; this.cd.markForCheck(); return; }
              const ARRAY = this.dataStoreService.getInsertionAction() === 'after' ? this.sections.splice(0, this.activeSectionIndex + 1) : this.sections.splice(0, this.activeSectionIndex);
              this.dataStoreService.resetInsertionAction();
              this.sections = [...ARRAY, FILTER_OBJ, ...this.sections];

              if (TREE_DATA.length <= 0) {
                this.snackBar.open('No data found for filtration', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: 'end', panelClass: ['warning-snackbar'] });
              } else {

                let GROUP_OBJ = {
                  id: this.commonService.generateUniqueId(),
                  type: activeSection.type,
                  name: activeSection.name,
                  sections: this.sections,
                }

                this.groups.splice(this.activeGroupIndex, 1, GROUP_OBJ);
                this.groupDataChild.emit(this.groups);
                this.loading = false;
              }

            })
          );
        }
      },
        error => {
          this.loading = false;
          console.error(error);
        });
  }





  /* this function return all sections under a group based on the user selection or clicked */
  getAllsections(activeGroupIndex) {
    this.groups.map((group, index) => {
      if (activeGroupIndex === index) {
        this.sections = group.sections;
        console.log(this.sections);
      }
    });
  }


  getTreeJSON(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.authService.getQuestionModal(this.subject_id)
        .subscribe((treedata: any) => {
          let TREE_DATA = treedata.maintopic_info ? [...treedata.maintopic_info] : [];
          resolve(TREE_DATA);
        },
          error => {
            console.error(error);
            reject(error);
          });
    });
  }

  ngOnDestroy() {
    if (this.subscription$.length > 0) {
      for (let subscription of this.subscription$)
        if (subscription) subscription.unsubscribe();
    }
  }

  async ngOnInit() {

    // let TREE_DATA: any[] = await this.getTreeJSON();
    // console.log({ TREE_DATA });

    this.authService.fetchTestModel(this.test_content_id)
      .subscribe((response: any) => {
        if (response.page_data !== null) {
          this.groups = response.page_data.groups ? response.page_data.groups : [];
          this.groups.forEach((group, index) => {
            group.sections.forEach((section, index) => {
              if (section.type === "FILTER") {
                section.isEditEnable = false;
                // section.treeData = [...TREE_DATA];
              }
            });
          });
          const OBJ = {
            time: response.page_data.time ? response.page_data.time : 0,
            groups: this.groups
          }
          this.groupDataChild.emit(OBJ);
        }
      },
        error => console.error(error));
  }

}




