import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GestureConfig, MatProgressSpinnerModule } from '@angular/material';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import 'hammerjs';
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { NgxPermissionsModule } from 'ngx-permissions';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './content/layout/layout.module';
import { AuthenticationModule } from './core/auth/authentication.module';
import { CoreModule } from './core/core.module';
import { AuthGuard } from './core/guards/auth.guard';
import { PublicGuard } from './core/guards/public.guard';
import { ClassInitService } from './core/services/class-init.service';
import { ClipboardService } from './core/services/clipboard.sevice';
import { DataTableService } from './core/services/datatable.service';
import { LayoutConfigStorageService } from './core/services/layout-config-storage.service';
import { LayoutConfigService } from './core/services/layout-config.service';
import { HeaderService } from './core/services/layout/header.service';
import { LayoutRefService } from './core/services/layout/layout-ref.service';
import { MenuAsideService } from './core/services/layout/menu-aside.service';
import { MenuHorizontalService } from './core/services/layout/menu-horizontal.service';
import { SubheaderService } from './core/services/layout/subheader.service';
import { LogsService } from './core/services/logs.service';
import { MenuConfigService } from './core/services/menu-config.service';
import { MessengerService } from './core/services/messenger.service';
import { PageConfigService } from './core/services/page-config.service';
import { QuickSearchService } from './core/services/quick-search.service';
import { SplashScreenService } from './core/services/splash-screen.service';
import { UserService } from './core/services/user.service';
import { UtilsService } from './core/services/utils.service';
import { FakeApiService } from './fake-api/fake-api.service';
import { rootReducer } from './store';
import { ErrorHandler, Injectable } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { LogicService } from './core/services/APIService/logic.service';
import { PartialsOptimizedModule } from './content/partials/partials-optimized.module';

if (environment.production) {
	Sentry.init({
		dsn: environment.sentry_dsn
	});
}


/* register 3rd party (image-resize-module) in ngx-quill editor */
// declare var Quill: any;
// import * as QuillNamespace from 'quill';
// Quill = QuillNamespace;
// import ImageResize from 'quill-image-resize-module';
// Quill.register('modules/imageResize', ImageResize);

// var FontAttributor = Quill.import('attributors/class/font');
// console.log('FontAttributor', FontAttributor);
// FontAttributor.whitelist = [
// 	'Tangerine'
// ];
// Quill.register(FontAttributor, true);




const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	// suppressScrollX: true
};


@Injectable()
export class SentryErrorHandler implements ErrorHandler {
	constructor() { }
	handleError(error) {
		const eventId = Sentry.captureException(error.originalError || error);
		// Sentry.showReportDialog({ eventId });
	}
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		environment.isMockEnabled ? HttpClientInMemoryWebApiModule.forRoot(FakeApiService) : [],
		LayoutModule,
		PartialsOptimizedModule,
		CoreModule,
		OverlayModule,
		AuthenticationModule,
		NgbModule.forRoot(),
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		StoreModule.forRoot({ sectionIndex: rootReducer })
	],
	providers: [
		AuthGuard,
		PublicGuard,
		LayoutConfigService,
		LayoutConfigStorageService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		UserService,
		UtilsService,
		ClassInitService,
		MessengerService,
		ClipboardService,
		LogsService,
		QuickSearchService,
		DataTableService,
		SplashScreenService,
		LogicService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},

		// template services
		SubheaderService,
		HeaderService,
		MenuHorizontalService,
		MenuAsideService,
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: GestureConfig
		},
		// {
		// 	provide: ErrorHandler,
		// 	useClass: SentryErrorHandler
		// }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
