import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'firstLine'
})
export class FirstLinePipe implements PipeTransform {
	transform(optionText: any): any {
		if(optionText.length > 0) {
            return optionText.split('\n')[0];
            // console.log(op[0]);
            // return op;
        }
	}
}