import { NgModule } from "@angular/core";
import { GrammerlyDirective } from "./grammerly.directive";

@NgModule({
	declarations: [
        GrammerlyDirective
    ],
    exports: [
        GrammerlyDirective
    ]
})
export class GrammerlyModule { }